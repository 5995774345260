function ClientAutocomplete() {}

ClientAutocomplete.prototype = (function() {

	return {
		constructor: ClientAutocomplete,
		bind : function() {
			$(".client-autocomplete").each(function() {
				$(this).autocomplete(Autocomplete.common);
			});
		}
	};
})();