(function($) {

    exportToMonitiButton = function(){
        var ajaxData = $.extend({},iAjaxData,{
            url : $(this).data("url"),
            success: fpFormSuccess
        });
        $.ajax(ajaxData);
    },

    $.fn.user = function() {
        return this.each(function() {
            $(".fpFormTabs").tabs();
            $("[name='availableRoles']").change(iFormMakeJoin).change(userCheckForeman);
            $("[name='availableMachines']").change(iFormMakeJoin).change(userCheckForeman);
            $(".fpSaveButton").click(fpSaveForm);
            $(".fpDeleteButton").click(fpDeleteForm);
            $(".fpBackButton").click(fpOpenLink);
            $(".fpExportToMonitiButton").click(exportToMonitiButton);
            $(".iFormRemoveJoinIcon").click(iFormRemoveJoin);
            $(".spreadsheet-form").spreadsheet();
        });
    }
}(jQuery));