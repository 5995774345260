(function($) {

    $.fn.spreadsheet = function(addCallback) {

    var deleteRow = function() {
        $(this).closest(".form-group").find("input, select, textarea").each(function() {
    	    if(!$(this).data("noclear")) {
    	        $(this).val("");
                defaultValue = $(this).data("default");
                $(this).find("option:selected").removeAttr("selected");
                $(this).val(defaultValue);

                if($(this).data("clearselects") == true) {
                  $(this).empty();
                };
            }
    	});

    	$(this).trigger("spreadsheet-row-deleted")
    	        .trigger("spreadsheet-updated");
    },

    addRow = function() {
        var $lastDataRow = $(this).closest(".form-group").prev();
        var $newDataRow =  $lastDataRow.clone();
        $newDataRow.insertAfter($lastDataRow);
        bindDelete($newDataRow);
        clearRow($newDataRow);
        attachCallback($newDataRow);
        $(this).trigger("spreadsheet-updated");
    },

    clearRow = function($row) {
        $row.find("input, select, textarea").each(function() {
            $(this).prop("disabled", false).val("");
            defaultValue = $(this).data("default");
            if ($(this).is("select")) {
                $(this).find("option").removeAttr("selected");
                if ($(this).hasClass("dynamic-select")) {
                    $(this).empty();
                }
            }
            $(this).val(defaultValue);
        });
    }

    addRowOnEnter = function(e) {
        if(e.keyCode === 13) {
            var $nextDataRow = copyRow.call(this);
            clearRow($nextDataRow);
        }
    }

    copyRow = function() {
        var $myDataRow = $(this).closest(".form-group");
        var $nextDataRow =  $myDataRow.clone();
        $nextDataRow.insertAfter($myDataRow);
        bindDelete($nextDataRow);
        bindCopy($nextDataRow);
        attachCallback($nextDataRow);
        $nextDataRow.find("input:not([type='hidden'])").eq(0).focus();
        return $nextDataRow;
    },

    attachCallback = function($row) {
        var callback = $row.closest(".iSpreadsheet").data("callback");
        if(callback !== undefined) {
           callback($row);
        }
    },

    bindAdd = function(contextElem) {
        $(contextElem).find(".iSpreadsheetAddRow").off('click');
        $(contextElem).find(".iSpreadsheetAddRow").click(addRow);
    },

    bindDelete = function(contextElem) {
       $(contextElem).find(".iSpreadsheetDeleteRow").click(deleteRow);
    },

    bindChange = function(contextElem) {
        $(contextElem).find("input:not([type='hidden']), select").change(function() {
            $(this).trigger("spreadsheet-updated");
        });
    },

    bindCopy = function(contextElem) {
        $(contextElem).find(".iSpreadsheetCopyRow").click(copyRow);
        $(contextElem).find("input").unbind("keyup").keyup(addRowOnEnter);
    },

    bindDrag = function(contextElem) {
        $(contextElem).closest(".iSpreadsheet").sortable({
            placeholder: "sortable-placeholder",
            items: ".fpDraggable"
        });
    };

	return this.each(function() {
	    $(this).data("callback", addCallback);
        bindAdd(this);
        bindDelete(this);
        bindChange(this);
        bindCopy(this);
        bindDrag(this);
	});
}
}(jQuery));