(function($) {

    $.fn.machine = function() {
        var copyMachine = function() {
            if(confirm(i18n('confirmAction'))) {
                var $form = fpGetForm.call(this);
                iShowMask($form);
                var ajaxData=$.extend({},iAjaxData,{
                    _this : $form,
                    url : $(this).data("url"),
                    success: formSuccess
                });
                $.ajax(ajaxData);
            } else {
                return false;
            }
        };

        var formSuccess = function (data, textStatus, jqXHR) {
            if(manageExceptions.call(this, jqXHR, data)) {
                return false;
            }

            if(jqXHR.getResponseHeader("requestOK") === "true") {
                alertify.success(i18n('ChangeSaved'));
                selectedId = jqXHR.getResponseHeader("id");
                openCopiedMachine(data, textStatus, jqXHR, selectedId);
            }
        }

        var openCopiedMachine = function (data, textStatus, jqXHR, selectedId) {
            var ajaxData=$.extend({},iAjaxData,{
                url : $(".fpCopyButton").data("callbackurl"),
                data: { machineId: selectedId },
                success: fpOpenViewSuccess
            });
            $.ajax(ajaxData);
        }

        $(document).ready(function() {
            $(".fpFormTabs").tabs();
            $("[name='availableUsers']").change(iFormMakeJoin).change(userCheckForeman);
            $(".fpSaveButton").click(fpSaveForm);
            $(".fpCopyButton").click(copyMachine);
            $(".fpDeleteButton").click(fpDeleteForm);
            $(".fpBackButton").click(fpOpenLink);
            $(".iFormRemoveJoinIcon").click(iFormRemoveJoin);
            $(".spreadsheet-form").spreadsheet();
        });
    }
}(jQuery));