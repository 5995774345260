/*************************************************************/
/******* FACTORY.JS - Obsługa JavaScript (funkcje ogólne) ********/
/*************************************************************/

/***************************** Zmienne globalne ******************************/


/* obiekt danych dla AJAX (w formacie JSON) */
var iAjaxDataJSON = {
    dataType: "json",
    type: "POST",
    error: iAjaxError,
    statusCode: {
        401: function() {
            alert("foo");
        }
    }
}

/*obiekt danych dla AJAX*/
var iAjaxData = {
	type: "POST",
	error: iAjaxError,
	complete: iAjaxComplete,
    statusCode: {
        401: manageExceptions,
        403: manageExceptions
    },
    beforeSend: function() {
        changeHash(this.url);
    }
}

/* domyślny obiekt opisujący okno modalne */
var iDefaultModalWindow = {
	position:['center',20],
	minWidth: $(window).width() - 100,
	dialogClass: "iModalWindow",
	modal: true,
	draggable: true,
    resizable: false,
    create: function() {
        $(this).css("maxHeight", 650);
    },
	close: function() {
        $(this).remove();
    }
};

/* domyslne ustawienia okna modalnego dla kolejnego okna */
var iNextModalWindow = {
	minWidth:830,
    position:[50,50]
};

var popoverOptions = {
    placement: function (context, source) {
        if ($(source).offset().top - $(window).scrollTop() < 280) {
            return "bottom";
        }
        return "top";
    }
};

var iFindParentTag = function(sender,tag){
	if($(sender).is(tag)){
		return $(sender);
	}
	$parent = $(sender).parents(tag).eq(0);
	if($($parent).size()==0){
		return false;
	}
	return $parent;
}

/* Zdarzenia wykonywane podczas ładowania strony */
$(document).ready(function() {

    //Obsługa kalendarza
     $(".iChooseDate").datepicker({
         dateFormat: "yy-mm-dd"
     });


     $(".iDataTable").each(function() {
         $(this).data("iAppendRegistry", false);
     });

    /*Tooltip*/
     $("*[title]:not([data-toggle='popover'])").tooltip();
     $('[data-toggle="popover"]').popover(popoverOptions);

     $('body').on('click', function (e) {
         $(".popover").remove();
     });

      $("#modal-sidebar").on('hidden.bs.modal', function (e) {
        $("#modal-sidebar").find(".modal-body").empty();
      });

});



/***************************** Obsługa AJAX ******************************/


/*Błąd pobrania danych*/
var iAjaxError = function() {
	console.log("Wystąpił błąd podczas pobrania danych za pomocą AJAX");
}

/* Domyślna funkcja obsługi poprawnego wywołania AJAX */
var iAjaxComplete = function() {;}

/*domyślny selektor dla danych formularza*/
var iDefaultFormData = {
	tag:$('body'),
	selector:"input[type=radio]:checked,input[type=checkbox]:checked,input[type=text][name],input[type=hidden][name]:not(:disabled),select[name],textarea[name],input[type=password][name],input[type=file][name],input[type=number][name]"
};

/* Multidimensional array to JSON */
function toJSON(array, up_to_dimension) {
    if (up_to_dimension < 0) {
        return "?";
    }

    var items = [];

    for (var i in array) {
        if (array[i].constructor == Array) {
            items.push(toJSON(array[i], up_to_dimension - 1));
        }
        else {
            items.push(array[i]);
        }
    }

    return "[" + items.join(", ") + "]";
}

/*Zapisywanie pozycji scrollBar*/
var saveScrollPosition = function() {
    sessionStorage.scrollTop = $(this).scrollTop();  
}

 var controllScroll = function() {
    $(".factory-view").scroll(saveScrollPosition)
    if (sessionStorage.position != undefined) {
        $(".factory-view").scrollTop(sessionStorage.scrollTop);
    } 
}

var scrollToLastEdited = function() {
    var itemRow = $(".factory-view").find(".last-entered-row")
    if (itemRow.offset()) {
            $(".factory-view").scrollTop(itemRow.offset().top - 200)          
    }
}

var scrollToPosition = function(form) {
    if (sessionStorage.scrollBottom === undefined && !form.prop('className').includes('noScrollOnLoad')) {
        scrollToLastEdited()
    } 
    sessionStorage.removeItem('scrollBottom');
}

var getFactoryName = function(clientName) {
    var hostname = window.location.hostname;
    var regexParse = new RegExp('[a-z\-0-9]{2,63}\.[a-z\.]{2,5}$');
    var urlParts = regexParse.exec(hostname);
    if(urlParts) {
        var subdomain = hostname.replace(urlParts[0],'').slice(0, -1);
        return subdomain == '' ? clientName : subdomain;
    }
    return clientName;
}

var createDatePicker = function(selector) {
    return $(selector).datepicker({dateFormat: "yy-mm-dd"})
            .datepicker('widget')
            .removeClass('ui-weekpicker');
}

var closeAllTooltips = function() {
    $("body").find(".tooltip").remove();
}

var adjustTextareas = function() {
    $('textarea').each(function(id, elem) {
       adjustTextarea(elem);
    });
}

var adjustTextarea = function(element) {
  element.style.height = "5px";
  element.style.height = (element.scrollHeight) + "px";
}