(function($) {

    $.fn.orderSpreadsheet = function(addCallback) {

    var NEW_DETAIL = "newDetail";

    var deleteRow = function() {
        var accordionSize = $(this).closest(".accordion").find(".orderDetailHeader").size();
        if(accordionSize > 1) {
            removeRow(this);
        }
        else {
            clearRow(this);
        }
    },

    clearRow = function(contextElem) {
        var $orderDetailFormRow = $(contextElem).closest(".orderDetailHeader").next();
        $orderDetailFormRow.find("input, select, textarea").each(function() {
            var clr = $(this).attr("minval") ? $(this).attr("minval") : "";
            $(this).val(clr).trigger("change");
        });

        $orderDetailFormRow.find(".drawing-file-row").addClass("fpDisplayNone"); //usunięcia wiersza z załącznikiem katalogu produktów

        var $tagsMultipleSelect = $orderDetailFormRow.find(".tags-multiple-select");
        $tagsMultipleSelect.empty();
        $tagsMultipleSelect.siblings(".ms-parent").remove();
        $tagsMultipleSelect.multipleSelect('destroy');
        $tagsMultipleSelect.multipleSelect({
            width: 300
        });

        $orderDetailFormRow.find("[name='reloadOperations']").val(true);
        $orderDetailFormRow.find("[name='notes']").css("height", "");
    },

    removeRow = function(contextElem) {
        var $accordionHeader = $(contextElem).closest(".orderDetailHeader").eq(0);
        var $accordionForm = $accordionHeader.next();
        $accordionHeader.remove();
        $accordionForm.remove();
    },

    addRow = function() {

        var $accordion = $(".accordion");
        var $accordionForm = $accordion.find(".orderDetailForm").last();
        var $accordionHeader = $accordionForm.prev();
        var $newForm = $accordionForm.clone();
        var $newHeader = $accordionHeader.clone();
        var deleteTrigger = $newHeader.find(".iSpreadsheetDeleteRow").eq(0);
        $newHeader.insertAfter($accordionForm);
        $newForm.insertAfter($newHeader);

        var accordionSize = $accordion.find(".orderDetailForm").size();
        var currentItemNo = $newHeader.find(".order-item-no").text();
        bindDelete($newHeader);
        bindUpdateable($newForm);
        clearRow(deleteTrigger);
        increaseItemNo($newHeader, currentItemNo);
        $accordion.accordion("destroy").accordion({active: accordionSize-1, collapsible: true});
        resetFormHeight($newForm);

        if(addCallback !== undefined) {
            addCallback($newForm);
        }
    },

    resetFormHeight = function($newForm) {
        $newForm.css("height", "");
    },

    updateHeader = function() {
        var inputName = $(this).attr("name");
        var inputValue = getInputText(this, inputName);
        $(this).closest(".orderDetailForm").prev()
            .find("span[data-input='" + inputName + "']")
            .text(inputValue);
    },

    getInputText = function(input, inputName) {
        var inputValue = $(input).val();
        if(inputName === "detailName") {
            return inputValue != '' ? inputValue : i18n(NEW_DETAIL);
        } else if(inputName === "detailUnit") {
            return $(input).find(":selected").text();
        } else if(inputName === "rawMaterialName") {
            return getRawMaterialNames(input);
        } else {
            return inputValue;
        }

    },

    getRawMaterialNames = function (input) {
        return $(input).closest(".raw-materials-spreadsheet")
            .find('input[name="rawMaterialName"]')
            .filter(function () {return $(this).val() !== "";})
            .map(function() {return $(this).val();})
            .toArray()
            .join(", ");
    },

    bindAdd = function() {
        $(".iSpreadsheetAddRow").click(addRow);
    },

    bindDelete = function(contextElem) {
        $(contextElem).find(".iSpreadsheetDeleteRow").click(deleteRow);
    },

    increaseItemNo = function(contextElem, currentItemNo) {
        var nextNo = parseInt(currentItemNo) + 1;
        $(contextElem).find(".order-item-no").text(nextNo);
    },

    bindUpdateable = function(contextElem) {
        $(contextElem).find(".updateable-input").change(updateHeader);
    };

	return this.each(function() {
        bindAdd();
        bindDelete(this);
        bindUpdateable(this);
	});
}
}(jQuery));