/**
 * @author visix
 */

(function($) {

    $.fn.remanent = function() {

        var bindExcelExport = function() {
            new ExcelExport().bind();
        },

        importRemanent = function() {
            if(confirm(i18n('RemanentConfirm'))) {
                var form = $(this).closest("form");
                var importRemanentEndpoint = form.attr("action");
                var stockCategory = $("#stockCategory").val();
                var remanentFile = $("[name='remanentFile']").prop('files')[0];
                var resultFilename = "";

                var formData = new FormData();
                formData.append("stockCategory", stockCategory);
                formData.append("remanentFile", remanentFile);
                iShowMask(form);

                fetch(importRemanentEndpoint, {
                    method: 'POST',
                    body: formData
                })
                .then(returnResponse)
                .then(function(data) {
                    iHideMask(form);
                    handleRemanentResponse(data);
                });
            }
        },

        returnResponse = function(response) {
            var isException = response.headers.map["exception_catched"];
            if(isException == "true") {
                return response.json();
            } else {
                resultFilename = response.headers.map.filename;
                return response.blob();
            }
        };

        handleRemanentResponse = function(data) {
            if(data.size) {
                var blob = new Blob([data], {type: "application/vnd.ms-excel"});
                saveAs(blob, resultFilename);
            } else if(!data.success) {
                alertify.error(data.errors.general.join(', '));
            }
        };

        return this.each(function() {
            $(".fpSaveButton").click(importRemanent);
            $(".fpBackButton").click(fpOpenLink);
            bindExcelExport();
        });

    }

}(jQuery));