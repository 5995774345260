/**
 * @author visix
 */

(function($) {

    $.fn.addStockTransferOperation = function() {

        var clearOldEntries = function($spreadsheet) {
            $spreadsheet.find(".form-group.toRemove").remove();
        },

        fillSpreadsheet = function(data, $spreadsheet) {
            for(var product in data) {
                $spreadsheet.find(".iSpreadsheetAddRow").click();
                var $row = $spreadsheet.find(".form-group").last().prev();

                $row.removeClass("toRemove")
                $row.find("input[name='itemName']").val(data[product].name);
                $row.find("input[name='itemId']").val(data[product].id);
            }
        },

        setNotes = function(order) {
            $("[name='notes']").val(i18n('NewOrderStockRelease') + ' ' + order);
        },

        bindAutocompleteMaterialsAutocomplete = function(context) {
            $(context).find("[name='itemName']").stockTransferMaterialAutocomplete();
        }

        return this.each(function() {
            $(this).find(".fpSaveButton").click(fpSaveForm);
            $(this).find(".fpBackButton").click(fpOpenLink);

            var $newStockReleaseSpreadsheet = $(this).find(".new-stock-transfer-form .iSpreadsheet");
            $newStockReleaseSpreadsheet.spreadsheet(bindAutocompleteMaterialsAutocomplete);

            bindAutocompleteMaterialsAutocomplete($newStockReleaseSpreadsheet);
        });

    }

}(jQuery));