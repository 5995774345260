(function($) {

    $.fn.orderDetailOperations = function() {

        var PRODUCTION_PROCESS_WORKFLOW_SPREADSHEET = ".production-process-workflow";

        assignOperationId = function() {
            var operationId = $(this).find("option:selected").data("operationid");
            $(this).next().val(operationId);
            var unitLabel = $(this).find("option:selected").data("unit-label");
            $(this).closest(".orderDetailOperation").find("input.machineUnitLabel").val(unitLabel);
        },

        bindOperationParametersChange = function($row){
            var productionProcessOperationId = $row.find("input[name='productionProcessOperationId']").val();
            var $destinationItem = $(PRODUCTION_PROCESS_WORKFLOW_SPREADSHEET).find("input[value='" + productionProcessOperationId + "']").parent();

                $row.find("input[name='effort']").change(function() {
                    $destinationItem.find("input[name='effort']").val($(this).val());
                });
                $row.find("input[name='machinePreparationTime']").change(function() {
                    $destinationItem.find("input[name='machinePreparationTime']").val($(this).val());
                });
                $row.find("textarea[name='notes']").change(function() {
                    $destinationItem.find("input[name='notes']").val($(this).val());
                });
        },

        disableOperationIntensityTab = function(e) {
            $(".fpFormTabs").tabs({disabled: [1]});
            $(".production-process-not-saved").show();
        }

        init = function(contextElement) {
            var $spreadsheet = $(contextElement).find(".iSpreadsheet");
            $spreadsheet.spreadsheet();

            $spreadsheet.find(".form-group").each(function() {
                bindOperationParametersChange($(this));
            });

            $(contextElement).find(".fpBackButton").click(fpOpenLink);
            $(contextElement).find(".fpSaveButton").click(fpSaveForm);
            $(contextElement).find(".fpFormTabs").tabs({activate: adjustTextareas});
            $(contextElement).find(".operations-toolbox").operationsToolbox();
            $(PRODUCTION_PROCESS_WORKFLOW_SPREADSHEET).on("dirty-data", disableOperationIntensityTab);
        };

        return this.each(function() {
            init(this);
        });
    }

}(jQuery));