/******************************************************
 *********  Użytkownicy **************
 */


var userCheckForeman = function() {
    var $combo = $("[name='user.operation.id']");
    var isForeman = false;
    $("#userJoinedRoles").find("[type='hidden']").each(function() {
        if($(this).val()=="2") {
            $combo.removeClass("iDisplayNone");
            isForeman = true;
        }
    });
    if(!isForeman) {
        $combo.addClass("iDisplayNone");
        $combo.val("");
    }
}