(function($) {

    $.fn.operationsToolbox = function() {
        var SPREADSHEET = undefined;
        var SPREADSHEET_WORKFLOW = undefined;
        var SELECTED_CLASS = "edit-operation-selected";
        var OPERATION_ITEM_TRIGGER = "#operation-item-trigger";

        var chooseOperation = function() {
            loadOperation({"operationName": $(this).data("name"), "operationId": $(this).data("value")});
        },

        getSelectedItem = function() {
            return SPREADSHEET.find("li." + SELECTED_CLASS);
        },

        loadOperation = function(operationData) {
            var $selectedOperation = getSelectedItem();
            setupOperationDetails($selectedOperation, operationData);

            if($selectedOperation.is(":last-child")) {
                addNewItem($selectedOperation);
            }

            var $nextOperation = $selectedOperation.next();
            selectOperation.call($nextOperation);

            markAsDirty();
        },

        addNewItem = function($currentItem) {
            var $newItem =  $currentItem.clone();
            $newItem.insertAfter($currentItem);
            setupOperationDetails($newItem, {"operationName": "--" + i18n('ChooseOperation') + "--", "operationId": ""});
            bindItemEvents($newItem);
        },

        setupOperationDetails = function($item, operationData) {
            $item.find("input[name='productionProcessOperationId']").val(operationData["productionProcessOperationId"]);
            $item.find("input[name='operation']").val(operationData["operationId"]);
            $item.find("input[name='effort']").val(operationData["effort"]);
            $item.find("input[name='machinePreparationTime']").val(operationData["machinePreparationTime"]);
            $item.find("textarea[name='notes']").val(operationData["notes"]);
            $item.find(".operation-name>span").text(operationData["operationName"]);
            $item.data("value", operationData["operationId"]);
            $item.data("name", operationData["operationName"]);
        },

        isLastOperation = function() {
            return SPREADSHEET_WORKFLOW.find("li").size() === 1;
        },

        removeOperation = function() {
            if(!isLastOperation()) {
                $(this).closest(".operation-item").remove();
                markAsDirty();
            }
        },

        markAsDirty = function() {
            SPREADSHEET_WORKFLOW.trigger("dirty-data");
        },

        selectOperation = function() {
            $(this).parent().find("." + SELECTED_CLASS).removeClass(SELECTED_CLASS);
            $(this).addClass(SELECTED_CLASS);
            $(OPERATION_ITEM_TRIGGER).focus();
        },

        resetOperations = function() {
            $(this).find(".operation-item:not(:first)").remove();
            selectOperation.call($(this).find(".operation-item:first"));
        },

        insertEmptyItem = function(e) {
            if(e.keyCode === 13) {
                var $currentItem = getSelectedItem();
                addNewItem($currentItem);
                $currentItem.removeClass(SELECTED_CLASS);
            }
        }

        bindItemEvents = function($item) {
            $item.click(selectOperation);
            $item.find(".iSpreadsheetDeleteRow").click(removeOperation);
        };

        return this.each(function() {
            var spreadsheetName = $(this).data("spreadsheet");
            SPREADSHEET = $(".iSpreadsheet[data-name='" + spreadsheetName + "']");
            SPREADSHEET_WORKFLOW = SPREADSHEET.find(".production-process-workflow");
            $(this).find(".operation-item").click(chooseOperation);
            SPREADSHEET.on("load-operation", function (event, operationData) {
                loadOperation(operationData);
            });
            SPREADSHEET.on("reset-operations", resetOperations);
            $(OPERATION_ITEM_TRIGGER).keyup(insertEmptyItem);
            SPREADSHEET_WORKFLOW.sortable({
                items: "li.operation-item:not(.disabled)",
                update: markAsDirty
            });
            bindItemEvents(SPREADSHEET.find(".operation-item:not(.disabled)"));
        });
    }

}(jQuery));