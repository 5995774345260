/***************************** Obsługa formularzy ******************************/

var ACTIONS = {
    SAVE: "SAVE",
    DELETE: "DELETE"
};

/*Generowanie obiektu z danych formularza*/
var iDefaultFormDataGenerator = function(option){
	var settings = $.extend({}, iDefaultFormData, option);
	var data={};
	$form = settings.tag;

	$form.find(settings.selector).each(function(i,val){
        if($(val).closest(".spreadsheet-form").size() == 0) {
            generateFormData(data, val);
        }
	});

    generateSpreadsheetData(data, $form);
	return data;
};

var generateFormData = function(data, val) {

    var value = $(val).val();
    if(data[$(val).attr("name")]){
        if(!$.isArray(data[$(val).attr("name")])) {
            data[$(val).attr("name")]=new Array(data[$(val).attr("name")]);
        }
        if(value) {
            data[$(val).attr("name")].push(value);
        }
    }else
        data[$(val).attr("name")]=value;
};

var generateSpreadsheetData = function(data, form) {

    form.find(".spreadsheet-form:not(.inactive)").each(function() {
        var rows = [];
        $(this).find(".form-group").each(function() {
            var newRow = {};
            $(this).find(iDefaultFormData.selector).each(function() {
                if($(this).closest(".row-spreadsheet").size() == 0) {
                    generateFormData(newRow, this);
                }
            });
            generateRowSpreadsheetData(newRow, $(this));
            if(Object.keys(newRow).length > 0) rows.push(JSON.stringify(newRow));
        });

        if(data[$(this).data("name")]){
            Array.prototype.push.apply(data[$(this).data("name")], rows);
        } else {
            data[$(this).data("name")] = rows;
        }
    });

};

var generateRowSpreadsheetData = function(rowData, spreadsheetRow) {

    spreadsheetRow.find(".row-spreadsheet").each(function() {
        var innerRows = [];
        $(this).find(".order-form-row").each(function() {
            var newInnerRow = {};
            $(this).find(iDefaultFormData.selector).each(function() {
                generateFormData(newInnerRow, this);
            });

            if(Object.keys(newInnerRow).length > 0) innerRows.push(newInnerRow);
        });

        if(rowData[$(this).data("name")]){
            Array.prototype.push.apply(rowData[$(this).data("name")], innerRows);
        } else {
            rowData[$(this).data("name")] = innerRows;
        }
    });

};

var fpGetCheckedIds = function(collectionName) {
    var ids = [];
    $("[name='" + collectionName + "']").each(function(idx, elem) {
        if($(elem).is(":checked")) {
            ids.push($(elem).val());
        }
    });
    return ids;
};


var fpGetForm = function() {
    return $(this).closest(".factory-view").find("form").eq(0);
};

/* Zapisywanie danych z formularza */
var fpSaveForm = function() {
    var $form = fpGetForm.call(this);
    iShowMask($form);
    var ajaxData=$.extend({},iAjaxData,{
    	_this : $form,
    	action: ACTIONS.SAVE,
    	data : iDefaultFormDataGenerator({tag:$form}),
    	url : $form.attr("action"),
    	success: fpFormSuccess
    });
    $.ajax(ajaxData);
}

/* Usuwanie danych z formularza */
var fpDeleteForm = function() {
    if(confirm(i18n('confirmDeletion'))) {
        var $form = fpGetForm.call(this);
        iShowMask($form);
        var ajaxData=$.extend({},iAjaxData,{
            _this : $form,
            action: ACTIONS.DELETE,
            url : $(this).data("url"),
            success: fpFormSuccess
        });
        $.ajax(ajaxData);
    } else {
        return false;
    }
}

/* Usuwanie załącznika */
var iDeleteFile = function() {
    var $sender = $(this);
    var ajaxData=$.extend({},iAjaxData,{
        url : $(this).data("url"),
        success: function(data, textStatus, jqXHR) {
            manageExceptions.call(this, jqXHR, data);
            iReloadModalWindow($sender.closest("div").attr("id"));
        }
    });
    $.ajax(ajaxData);
}

/* Akcja po zamknięciu formularza */
function manageExceptions(jqXHR, data) {

    handlePermissionException(jqXHR);

    if (jqXHR.getResponseHeader("EXCEPTION_CATCHED") == "true") {
        showErrors(data);
        iHideMask(this._this);
        return true;
    }

    return false;
}

function showErrors(data) {
    var errorPropagationSeconds = 10;
    for (var i = 0; i < data.errors.general.length; i++) {
        alertify.error(data.errors.general[i], errorPropagationSeconds);
    }
}

function handlePermissionException(jqXHR) {
    if(jqXHR.status === 401 || jqXHR.status === 403) {
        alertify.error(i18n("AccessDenied"));
        $(".ui-dialog-content").dialog("close");
    }
}

var fpFormSuccess = function(data, textStatus, jqXHR){
    if(manageExceptions.call(this, jqXHR, data)) {
        return false;
    }

    if(jqXHR.getResponseHeader("requestOK")=="true"){

        if(jqXHR.getResponseHeader("redirect")){

            document.location.href = jqXHR.getResponseHeader("redirect");

        } else if($(this._this).parent().data("reload")===false) {

            loadDataToPreviousDialog(this._this, jqXHR);
            $(this._this).closest(".ui-dialog-content").dialog("close");

        } else if($(this._this).parent().data("reload")===true) {

            reloadForm($(this._this), data);

        } else {
            $(".fpBackButton").trigger("click");
        }

        alertify.success(i18n('ChangeSaved'));
        if(this.action === ACTIONS.SAVE) {
            $(this._this).trigger("formSaved", [jqXHR.getResponseHeader("response-parameter")]);
        }

	} else {
        reloadFormWithInvalidData($(this._this), data, jqXHR);
	    iHideMask(this._this);
	}
};

var reloadFormWithInvalidData = function(form, data, jqXHR) {
    if(jqXHR.getResponseHeader("partial-form-reload")=="true") {
        reloadFormPart(form, data);
    } else {
        reloadForm(form, data);
    }
}

/* Załadowanie danych do poprzedniego okna */
var loadDataToPreviousDialog = function(form, jqXHR) {
    var senderLink = $(form).parent().parent().data("sender");
    var savedId = jqXHR.getResponseHeader("id");
    var idField = $(senderLink).data("returnId");
    var nameField = $(senderLink).data("returnName");

    $(senderLink).data("savedId", savedId).trigger("reload-data");

    if(idField && nameField) {
        $("[name='" + idField + "']").val(savedId);
        $("[name='" + nameField + "']").val(jqXHR.getResponseHeader("name"));
    }
};

var reloadForm = function(elem, data) {
    elem.closest(".fpContent").html(data);
};

var reloadFormPart = function(elem, data) {
    var selector = "*[data-partial-form-reload='true']";
    var reloadPart = elem.closest(".fpContent").find(selector);
    var bindEventsFunction = reloadPart.data("bind-events");
    var partialHtml = $(data).find(selector).html();
    reloadPart.html(partialHtml);

    if(bindEventsFunction) {
        window[bindEventsFunction]();
    }
};

/* Otwieranie nowego okna przeglądarki */
var iNewWindow = function(url,subject, width, height) {
	window.open(url,subject,"scrollbars=yes, width=" + width + ", height=" + height);
}



/* Usuwanie powiązania z arkusza */
var iFormRemoveJoin = function() {
    $(this).parent().parent().remove();
    if($(this).data("callback") !== undefined)
        eval($(this).data("callback") + "()");
}
/* Dodawanie do arkusza nowego powiązania */
var iFormMakeJoin = function() {
   var $combo = $("#" + $(this).data("selectfrom"));
   var $table = $("#" + $(this).data("bringto"));
   var $callback = $(this).data("callback");

   if($table.find("input[type='hidden'][value='" + $combo.val() + "']").size() == 0 && $combo.val()!="") {
       var $newRow = $('<tr></tr>');
       var $newCell = $('<td></td>');
       var $newHidden = $('<input type="hidden" name="' + $(this).data("newelements") + '" value="' + $combo.val() + '"/>')
       var $iconRemove = $('<i class="fa fa-times iFormRemoveJoinIcon" ' + (($callback != "") ? ('data-callback="'+$callback+'"') : "") + '></i>');
       $iconRemove.click(iFormRemoveJoin);
       $newCell.append($newHidden);
       $newCell.append($iconRemove);
       $newCell.append($combo.find("option:selected").text());
       $newRow.append($newCell);

       if($table.find("tbody>tr").size() == 0)    //podpinanie nowego wiersza do tabeli
        $table.find("tbody").append($newRow);
       else
        $newRow.insertBefore($table.find("tbody>tr:first"));
   }
}