function ExcelExport() {}

ExcelExport.prototype = (function() {

    var exportToFile = function() {
        var form = $(this).closest(".factory-view").find("form").eq(0);
        iShowMask(form);
        var ajaxData=$.extend({},iAjaxData,{
            _this : form,
            data : iDefaultFormDataGenerator({tag:form}),
            dataType: 'binary',
            url : $(this).data("url"),
            beforeSend: function() {},
            success: startDownloading
        });
        $.ajax(ajaxData);
    },

    startDownloading = function(data, textStatus, jqXHR) {
        iHideMask(this._this);
        if (jqXHR.getResponseHeader("EXCEPTION_CATCHED") == "true") {
            alertify.error(i18n('validation.downloadError'));
            return false;
        }

        var blob = new Blob([data], {type: "application/vnd.ms-excel"});
        var fileName = jqXHR.getResponseHeader("filename");
        saveAs(blob, fileName);

    };

	return {
		constructor: ExcelExport,
		bind : function() {
			$(".fpExportButton").each(function() {
				$(this).unbind("click").click(exportToFile);
			});
		},
        download : function($this) {
		    exportToFile.call($this);
        }
	};
})();