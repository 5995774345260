/**
 * Created by visix on 25.05.2017.
 */

(function($) {

    $.fn.order = function() {

        var actualOpenedCatalogueFrom;
        var DETAILS_SPREADSHEET = $("[data-name='orderDetails']"),
        STATUS_ID = $("[name='order.status.id']"),
        VARIANTS_CHOICE = {
            formatSelectAll: function () {
              return i18n('All');
            },
            formatAllSelected: function () {
              return i18n('AllChosen');
            },
            formatCountSelected: function(count, total) {
                return count + ' ' + i18n('from2') + ' ' + total + ' ' + i18n('chosen');
            },
            formatNoMatchesFound: function () {
              return i18n('NoChoice');
            },
            placeholder: i18n('ChooseProductVariants'),
            multiple: true,
            multipleWidth: true
        };

        uploadAttachments = function(orderNumber) {
            var fileUploadEndpoint = $(this).data("file-upload");
            var formData = new FormData();

            formData.append("orderNumber", orderNumber);
            $("[name='attachments'][type='file'], [name='orderAttachments'][type='file']").each(function (i) {
                formData.append("files["+i+"]", $(this).prop('files')[0]);
            });

            if(fileUploadEndpoint) {
                fetch(fileUploadEndpoint, {
                    method: 'POST',
                    body: formData
                })
                .then(function(response) {
                    return response.json();
                })
                .then(function (data) {
                    if(!data.success) {
                        alertify.error(i18n('AttachmentsSendingError'));
                    } else {
                        alertify.success(i18n('AttachmentsSendingFinished'));
                    }
                });
            }
        },

        remove = function() {
            fpDeleteForm.call(this);
        },

        confirmOrder = function() {
            if(confirm(i18n('confirmAction'))) {
                changeStatusAndSave(2, this);
            }
        },

        changeStatusAndSave = function(statusId, button) {
            STATUS_ID.val(statusId);
            save.call(button);
        },

        copy = function () {
            var $form = $(this).closest(".factory-view").find("form").eq(0);
            var ajaxData=$.extend({},iAjaxData,{
                _this : $form,
                data : iDefaultFormDataGenerator({tag:$form}),
                url : $(this).data("url"),
                success: copySuccess
            });
            $.ajax(ajaxData);
        },

        copySuccess = function (data, textStatus, jqXHR) {
            if($(".orderDetailForm", data).length < 1) {
                alertify.message(i18n("NoDetailsFound"));
            } else {
                reloadForm($(this._this), data);
            }
        },

        back = function(event) {
            var ajaxData = $.extend({},iAjaxData,{
                url : $(this).data("back-url"),
                type: 'GET',
                success: backSuccess
            });
            $.ajax(ajaxData);
        },

        save = function() {
            var addMode = $('[name="addMode"]').val()
            if(addMode || confirm(i18n('confirmAction'))) {
                fpSaveForm.call(this);
            }
        },

        backSuccess = function(data, textStatus, jqXHR) {
            manageExceptions.call(this, jqXHR, data);
            var $fpContent = $(".fpContent");
            $fpContent.html(data);

            $fpContent.desktop();
            $fpContent.find(".menuLink").click(fpOpenLink);
            selectLastEditedRow($fpContent.find(".fpDataTable").eq(0));
        },

        bindAutocompletes = function(context) {
            $(context).find("[name='detailName']").autocomplete(getDetailsAutocomplete());
            var $rawMaterialsAutocomplete = $(context).find("[name='rawMaterialName']").autocomplete(getRawMaterialsAutocomplete());

            if($rawMaterialsAutocomplete.size() > 0) {
                $rawMaterialsAutocomplete.each(function() {
                    $(this).data("ui-autocomplete")._renderItem = markMaterialsUnderMin;
                });
            }

        },

        markMaterialsUnderMin = function(list, item){
            var listItem = $("<li></li>")
            .data("item.autocomplete", item)
            .append("<a><b>" +  item.label + "</b> [" + item.category + "] ( " + item.actualAmount + " " + item.unit + ")</a>")
            .appendTo(list);

            if (parseFloat(item.actualAmount) < parseFloat(item.minimalAmount)) {
                listItem.addClass("raw-material-under-min");
            }

            return listItem;
        },

        loadFromCatalogue = function(e) {
            actualOpenedCatalogueFrom = this;
            fpOpenContext.call(this, e, {isWide: true});
        }

        bindSpreadsheetEvents = function(context) {
            bindAutocompletes(context);
            $(context).find(".order-new-attachment").click(showUpload);
            $(context).find(".load-from-catalogue").click(loadFromCatalogue);
            $(context).find(".accordion").on("accordionactivate", adjustAccordionPanel);
            adjustTextareas();
        },

        adjustAccordionPanel = function() {
            adjustTextareas();
            $(this).find(".orderDetailForm").css("overflow", "auto");
        },

        setClientDetailsFilter = function() {
            if($(this).is(":checked")) {
                $(this).val($(this).data("clientid"));
            } else {
                $(this).val(null);
            }
        },

        getDetailsAutocomplete = function() {
            return $.extend({},Autocomplete.common, {
                source: function (request, response) {
                    $.ajax({
                        url: $(this.element).attr("data-url"),
                        dataType: "json",
                        data: {
                            term: request.term,
                            clientId: $("[name='onlyThisClient']:checked").val()
                        },
                        success: function (data) {
                            response($.map(data, function (item) {
                                return {
                                    name: item.constructionDetailName,
                                    label: item.constructionDetailLabel,
                                    value: item.constructionDetailId,
                                    constructionDetailId: item.constructionDetailId,
                                    isDefault: item.default,
                                    amount: item.amount,
                                    unitId: item.unitId,
                                    productId: item.productId,
                                    productName: item.productName,
                                    notes: item.notes,
                                    attachment: item.attachment,
                                    operations: item.operations,
                                    tags: item.tags
                                };
                            }));
                        }
                    });
                },
                select: function( event, ui ) {
                    var $target = Autocomplete.common.getTarget(this);
                    var $orderRow = $target.closest(".form-group");
                    fillOrderDetailRow($orderRow, ui.item);
                    this.value = ui.item.name;

                    if($target) {
                        $(this).trigger("change");
                        $target.val(ui.item.value).trigger("change");
                    }

                    return false;
                }
            })
        },

        fillOrderDetailRow = function($orderRow, item) {

            var $name = $orderRow.find("[name='detailName']");
            var $id = $orderRow.find("[name='detailId']");
            var $productionProcess = $orderRow.find("[name='productionProcessId']");
            var $orderedAmount = $orderRow.find("[name='orderedAmount']");
            var $unit = $orderRow.find("[name='detailUnit']");
            var $notes = $orderRow.find("[name='notes']");
            var $tagsSelect = $orderRow.find(".tags-multiple-select");
            var $drawingFile = $orderRow.find("[name='drawingFile']");
            var $reloadOperations = $orderRow.find("[name='reloadOperations']");
            var $drawingFileFormRow = $drawingFile.closest(".order-form-row");
            var $formRow = $drawingFileFormRow.closest(".orderDetailForm");

            $id.val(item.constructionDetailId).trigger("change");
            $name.val(item.constructionDetailName).trigger("change");
            $orderedAmount.val(item.amount).trigger("change");
            $unit.val(item.unitId).trigger("change");
            $notes.val(item.notes).trigger("change");
            $reloadOperations.val("true");

            if(item.attachment) {
                $drawingFileFormRow.removeClass("fpDisplayNone");
                $drawingFile
                    .attr("href", "/show/file/technical_diagrams/" + item.constructionDetailId + "/" + item.attachment)
                    .text(item.attachment);
                    $formRow.css("height", "360px");
            }
            else {
                $drawingFileFormRow.addClass("fpDisplayNone");
                $formRow.css("height", "320px");
            }

            if(item.operations) {
                $productionProcess.next().text(item.operations).show();
                $productionProcess.hide();
            } else {
                $productionProcess.next().hide();
                $productionProcess.show();
            }
            $tagsSelect.empty();

            if(item.tags) {
                fillTags(item.tags, $orderRow);
            }
        },

        fillTags = function(tags, $orderRow){
            var $tagsSelect = $orderRow.find(".tags-multiple-select");
            $tagsSelect.multipleSelect('destroy');
            for (var i = 0; i < tags.length; i++) {
                var tagGroup = tags[i];
                var $optgroup = $('<optgroup>', { label: tagGroup.name });

                for (var j = 0; j < tagGroup.value.length; j++) {
                    var tagValue = tagGroup.value[j];
                    var $option = $('<option>', {
                         value: tagGroup.name + ":" + tagValue,
                         text: tagValue
                    });
                    $optgroup.append($option);
                }
                $tagsSelect.append($optgroup);
            }

            if(tags.length > 0) {
                $tagsSelect.parent().show();
                $tagsSelect.multipleSelect(VARIANTS_CHOICE);
            } else {
                $tagsSelect.parent().hide();
            }
        },

        getRawMaterialsAutocomplete = function(){
           return $.extend({},Autocomplete.common, {
                source: function (request, response) {
                    $.ajax({
                        url: $(this.element).attr("data-url"),
                        dataType: "json",
                        data: {
                            term: request.term
                        },
                        success: function (data) {
                            response($.map(data, function (item) {
                                return {
                                    label: item.name,
                                    value: item.id,
                                    unit: item.unit,
                                    minimalAmount: item.minimalAmount,
                                    actualAmount: item.actualAmount,
                                    category: item.category
                                };
                            }));
                        }
                    });
                }
            })
        },

        showUpload = function(e) {
            if(confirm(i18n("confirmAction"))) {
                $(this).hide();
                $(this).prev().hide();
                $(this).next().prop("disabled", false).show();
                $(this).next().next().remove();
            }
        },

        getActiveProduct = function(id, contextElement) {
            return $(contextElement).find(".orderDetailForm")
                .index($("[name=\"id\"][value=\"" + id + "\"]")
                    .closest(".orderDetailForm"));
        },

        addOrderDetailRows = function(itemData) {
            var availableRows = $(actualOpenedCatalogueFrom).closest(".orderDetailForm").nextAll().filter(".orderDetailForm").length + 1;
            for(var i = availableRows; i < itemData.length; i++) {
                $(".iSpreadsheetAddRow").click();
            }
        },

        fillOrderDetailRows = function(itemData) {
            for(var i = 0; i < itemData.length; i++) {
                fillOrderDetailRow($(actualOpenedCatalogueFrom).closest(".form-group"), itemData[i]);
                actualOpenedCatalogueFrom = $(actualOpenedCatalogueFrom).closest(".orderDetailForm").next().next().find(".load-from-catalogue");
            }
        },

        bindEvents = function (contextElement) {
            var $saveButton = $(contextElement).find(".fpSaveButton");
            $saveButton.click(save);
            $(contextElement).find(".confirm-order").click(confirmOrder)
            $(contextElement).find(".fpDeleteButton").click(remove);
            $(contextElement).find(".fpCopyButton").click(copy);
            $(contextElement).find(".fpBackButton").click(back);
            $(contextElement).find(".fpBackToOrderButton").click(back);
            $(contextElement).find(DETAILS_SPREADSHEET).orderSpreadsheet(bindSpreadsheetEvents);
            $(contextElement).find("[name='onlyThisClient']").change(setClientDetailsFilter);
            $(contextElement).find(".order-attachments-list .order-new-attachment").click(showUpload);
            $(contextElement).find("form").on("formSaved", function(e, orderNumber) {
                uploadAttachments.call($saveButton, orderNumber);
            });
            bindSpreadsheetEvents(DETAILS_SPREADSHEET);

            var active = getActiveProduct($(".order-products").data("selected-product-id"), contextElement);
            $(contextElement).find( ".accordion" ).accordion({active: active > 0 ? active : 0, collapsible: true});

            $(".accordion").on('input',"input[name='orderedAmount']", function (e) {
                $(this).val($(this).val().replace(/^[^1-9][^0-9]*$/g, ''));
            })
            .on('blur', "input[name='orderedAmount']", function() {
                if ($(this).val()<1) {
                    $(this).val(1).change()
                }
            });

            $("a[title]").tooltip();
            $(".tags-multiple-select").multipleSelect(VARIANTS_CHOICE);
        };

        $.fn.order.fillOrderItemFromCatalogue = function(itemData) {
            addOrderDetailRows(itemData);
            fillOrderDetailRows(itemData);
            fpCloseModalSidebar();
        }

        return this.each(function() {
            bindEvents(this);
        });
    }

}(jQuery));