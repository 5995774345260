/**
 * Created by visix on 9.06.2017.
 */

(function($) {

    $.fn.desktop = function() {

        var REFRESH_INTERVAL = 15000;
        var MACHINES_LIST = $(".machinesList");
        var CONTENT = $(".fpContent");
        var CONTENT_CENTER = $(".fpContentCenter");
        var SEARCH_PRODUCTS_CONTAINER = $(".order-product-search");
        var SEARCH_PRODUCTS_FIELD = $(".order-product-search input[type='text']");
        var SEARCH_WEEK_FIELD = $("#weekPickerInput");
        var TEXT_WEEK_FIELD = $("#weekPicker");
        var DEPARTMENT_FIELD = $("#department");
        var MOVE_DIRECTION = {PREVIOUS: 'PREVIOUS', NEXT: 'NEXT'};

        searchOrderProducts = function(e) {
            if(e.keyCode == 13) {
                filterList(MACHINES_LIST, "li.machine-order-detail", SEARCH_PRODUCTS_CONTAINER);
            }
        },

        isToMarkFilteredItemsOn = function() {
            return SEARCH_PRODUCTS_CONTAINER.find(".mark-filtered-items > input[type='checkbox']").is(":checked");
        },

        isToRefresh = function() {
            return SEARCH_PRODUCTS_CONTAINER.find(".refresh-planning-board > input[type='checkbox']").is(":checked");
        },

        filterList = function(list, itemSelector, $searchPanel) {
            var searchPhrase = $searchPanel.find("input[type='text']").val();

            if(isToMarkFilteredItemsOn()) {
                hideFilterOutItems(list, itemSelector, searchPhrase);
            } else {
                filterPlanningBoard();
            }
        },

        hideFilterOutItems = function(list, itemSelector, searchPhrase) {
            iShowMask(MACHINES_LIST);
            fetchData('', '', DEPARTMENT_FIELD.val(), function() {
                bindMachineListEvents();
                showFilteredOutItems(list, itemSelector, searchPhrase);
            });
        },

        changeFilteringMode = function() {
            filterList(MACHINES_LIST, "li.machine-order-detail", SEARCH_PRODUCTS_CONTAINER);
        },

        showFilteredOutItems = function(list, itemSelector, searchPhrase) {
            var filterOutClass = "filtered-out-item";

            list.find(itemSelector)
                .removeClass(filterOutClass);

            list.find(itemSelector).each(function() {
                var cellText = ($(this).text() + $(this).data("search")).toLowerCase().trim();
                if(cellText.indexOf(searchPhrase.toLowerCase().trim()) == -1) {
                    $(this).addClass(filterOutClass);
                }
            });
        },

        filterMachines = function() {
            var selectedOrders = ORDER_LIST.find(".order-selected").map(function() {
                return $(this).data('id');
            }).get();
            if(selectedOrders.length > 0) {
                MACHINES_LIST.find(".machine-order-detail").hide();
                MACHINES_LIST.find(".machine-order-detail").each(function() {
                    if(selectedOrders.includes($(this).data("order"))) {
                        $(this).show();
                    }
                });
            }
            else {
                MACHINES_LIST.find(".machine-order-detail").show();
            }
        },

        filterPlanningBoard = function() {
            iShowMask(MACHINES_LIST);
            reloadMachines();
        },

        reloadMachines = function() {
            closeAllTooltips();
            fetchData(SEARCH_PRODUCTS_FIELD.val(), SEARCH_WEEK_FIELD.val(), DEPARTMENT_FIELD.val(), bindMachineListEvents);
        },

        fetchData = function(searchPhrase, searchWeek, departmentId, callback) {
            if($(".machinesList").size() > 0) {
                var url = MACHINES_LIST.data("reload");
                MACHINES_LIST.load(url, {searchPhrase: searchPhrase, searchWeek: searchWeek, departmentId: departmentId}, callback);
            }
        },

        showOrderDetailPreview = function(e) {
            MACHINES_LIST.find(".last-entered-row").removeClass("last-entered-row");
            $(this).parent().addClass("last-entered-row");
            fpOpenContext.call(this, e);
        }

        bindMachineListEvents = function() {
            iHideMask(MACHINES_LIST);
            MACHINES_LIST.find(".machine-entry > li").each(function() {
                $(this).find(".machine-order-detail > a").on("click", showOrderDetailPreview);
                $(this).find(".machine-task-header .move-to-prev-operation").on("click", moveToPrevOperation);
                $(this).find(".machine-task-header .move-to-next-operation").on("click", moveToNextOperation);
                $(this).find(".remove-priority").on("click", removePriority);

                var $tasksList = $(this).find(".machine-order-details");
                $tasksList.sortable({
                    handle: ".order-task-drag",
                    connectWith: getConnectWithClass($tasksList),
                    placeholder: "sortable-placeholder",
                    update: updateOrderDetailPriorities,
                    scroll: true,
                    start: function(event, ui) {
                        $(getNotConnectedWithClass($tasksList)).addClass('machine-sortable-forbidden');
                        ui.placeholder.height(ui.item.height());
                    },
                    stop: function(event, ui) {
                        $(getNotConnectedWithClass($tasksList)).removeClass('machine-sortable-forbidden');
                    }
                }).disableSelection();

            });

            $('[data-toggle="popover"]').popover(popoverOptions);
            $("*[title]:not([data-toggle='popover'])").tooltip();
        },

        getConnectWithClass = function($tasksList) {
            return ".connect-with-" + $tasksList.data("operation");
        },

        getNotConnectedWithClass = function($tasksList) {
            return ".machine-order-details:not(" + getConnectWithClass($tasksList) + ")";
        },

        updateOrderDetailPriorities = function(e, ui) {
            changeMachine(this, ui);
            ui.item.data("pinned", "true");

            var priorities = {};
            $(this).find("li").each(function() {
                var detailId = $(this).data("detail");
                var isPinned = $(this).data("pinned");
                if(!priorities[detailId] && isPinned) {
                    priorities[detailId] = $(this).index()+1;
                }
            });

            var ajaxData = $.extend({},iAjaxData,{
                url : $(this).data("updatepriority"),
                data: {
                    priorities: JSON.stringify(priorities)
                }
            });
            $.ajax(ajaxData);
        },

        changeMachine = function(taskList, ui) {
            var oldMachine = $(taskList).data("machine");
            var newMachine = ui.item.parent().data("machine");

            if(oldMachine != newMachine) {
                var ajaxData = $.extend({}, iAjaxData, {
                    url: $(taskList).data("changemachine"),
                    data: {
                        machineId: newMachine,
                        taskId: ui.item.data("id")
                    }
                });
                $.ajax(ajaxData);
            }
        },

        moveToNextOperation = function() {
            if(confirm(i18n("ConfirmMoveToNextOperation"))) {
                moveToOperation(this, MOVE_DIRECTION.NEXT);
            }
        },

        moveToPrevOperation = function() {
            if(confirm(i18n("ConfirmMoveToPrevOperation"))) {
                moveToOperation(this, MOVE_DIRECTION.PREVIOUS);
            }
        },

        moveToOperation = function(trigger, moveDirection) {
            iShowMask(MACHINES_LIST);
            var $machineEntry = $(trigger).closest(".machine-order-detail");
            var orderDetailId = $machineEntry.data("detail");
            var taskId = $machineEntry.data("id");
            var endpointUrl = MACHINES_LIST.data("move");
            var ajaxData = $.extend({}, iAjaxData, {
                url: endpointUrl,
                data: {
                    orderDetailId: orderDetailId,
                    taskId: taskId,
                    moveDirection: moveDirection
                },
                success: function(data, textStatus, jqXHR) {
                    manageExceptions.call(this, jqXHR, data);
                    reloadMachines();
                }
            });
            $.ajax(ajaxData);
        },

        removePriority = function(e) {
            if(confirm(i18n("ConfirmRemovePriority"))) {
                e.stopPropagation();
                iShowMask(MACHINES_LIST);
                var $elem = $(this);
                var ajaxData = $.extend({}, iAjaxData, {
                    url: $elem.data("url"),
                    beforeSend: function() {},
                    success: function(data, textStatus, jqXHR) {
                        $elem.remove();
                        iHideMask(MACHINES_LIST);
                    }
                });
                $.ajax(ajaxData);
            }
        },

        changeMachinesView = function() {
            var addClass = "table";
            var removeClass = "columns";
            var machineEntries = MACHINES_LIST.find(".machine-entry");

            machineEntries.addClass("machine-panel");
            MACHINES_LIST.parent().addClass("machine-panels");

            if($(this).data("view") == "columns") {
                addClass = "columns";
                removeClass = "table";
                machineEntries.removeClass("machine-panel");
                MACHINES_LIST.parent().removeClass("machine-panels");
            }

            $(this).data("view", removeClass).find("i")
            .removeClass("fa-" + removeClass)
            .addClass("fa-" + addClass);
            $(this).find("[name='criteria.displayMode']").val(removeClass);
            MACHINES_LIST.data("view", removeClass.toUpperCase());

        },

        loadOrderForm = function() {
            var ajaxData = $.extend({}, iAjaxData, {
                url: $(event.srcElement).data("url"),
                success: loadOrderFormSuccess
            });
            $.ajax(ajaxData);
        },

        loadOrderFormSuccess = function (data, textStatus, jqXHR) {
            manageExceptions.call(this, jqXHR, data);
            $(".fpContent").addClass("ui-dialog-content").html(data);
        },

        showSchedulePointer = function(e) {
            var $pointer = $(this).parent().next(".schedule-timeline-pointer").eq(0);
            $pointer.css("left", event.pageX-50);
        },

        saveSchedule = function() {
            var currentLocation = window.location.hash;
            var $button = $(this);
            var ajaxData = $.extend({}, iAjaxData, {
                url: $(this).data("url"),
                success: function(data, textStatus, jqXHR) {
                    $button.attr("disabled", false);
                    manageExceptions.call(this, jqXHR, data);
                    filterList(MACHINES_LIST, "li.machine-order-detail", SEARCH_PRODUCTS_CONTAINER);
                    changeHash(currentLocation);
                    alertify.success(i18n("SavingScheduleSuccess"));
                }
            });

            if(confirm(i18n("SavingScheduleConfirmed"))) {
                $button.attr("disabled", true);
                alertify.warning(i18n("SavingScheduleInProgress"));
                $.ajax(ajaxData);
            }
        },

        init = function() {

            setInterval(function(){
                if(!isToMarkFilteredItemsOn() && isToRefresh()) {
                    reloadMachines();
                }
            }, REFRESH_INTERVAL);

            $(".order-form-link").unbind("click").click(loadOrderForm);
            $(".machine-panels-change-view").click(changeMachinesView);
            $(".order-product-search input[type='text']").keyup(searchOrderProducts);
            $(".order-product-search .mark-filtered-items > input[type='checkbox']").change(changeFilteringMode);
            $(".schedule-timeline li").click(showSchedulePointer);
            $(".order-product-search .save-planning-board").click(saveSchedule);

            bindMachineListEvents();
            convertToWeekPicker($("#weekPicker"));
            SEARCH_WEEK_FIELD.change(function() {
                filterList(MACHINES_LIST, "li.machine-order-detail", SEARCH_PRODUCTS_CONTAINER);
            });
            TEXT_WEEK_FIELD.change(function() {
                SEARCH_WEEK_FIELD.val(TEXT_WEEK_FIELD.val());
            });
            DEPARTMENT_FIELD.change(function() {
                filterList(MACHINES_LIST, "li.machine-order-detail", SEARCH_PRODUCTS_CONTAINER);
            });

            console.log("Initializing dashboard events");
        };

        return {
            init : this.each(function() {
                init(this);
            }),

            showOrder : loadOrderForm
        }


    }

}(jQuery));