/**
 * @author visix
 */

(function($) {

    $.fn.productionOrders = function() {

        var ORDERS_IN_PROGRESS_STATUS = "IN_PROGRESS";
        var ORDERS_SUSPENDED_STATUS = "SUSPENDED";
        var ORDERS_FINISHED_STATUS = "FINISHED";
        var ORDERS_DELIVERED_STATUS = "DELIVERED";
        var PRODUCTION_ORDERS_REGISTRY = $(".production-orders-registry");

        updateInvoiceNumber = function (e) { //dotyczy OrderDetail a nie Order
           var _this = $(this);
           var askForInvoiceNumber = _this.data("ask-for-invoice-number");
           var invoiceNumber;
           if(askForInvoiceNumber) {
               invoiceNumber = prompt(i18n("order.updateInvoiceNumber"));
           }
           var orderDetailIds = fpGetCheckedIds("orderDetail[]");
           if(invoiceNumber || !askForInvoiceNumber) {
               var ajaxData = $.extend({},iAjaxData,{
                   url : _this.data("url"),
                   data: {
                       orderDetailIds: orderDetailIds,
                       invoiceNumber: invoiceNumber
                   },
                   success: function(invoiceData, textStatus, jqXHR) {
                       if(manageExceptions.call(this, jqXHR, invoiceData)) {
                           return false;
                       }
                       alertify.success(i18n("InvoiceGenerated"));
                       updateRegistryRows(orderDetailIds, invoiceData);
                       toggleUpdateInvoiceButtonActivity();
                   }
               });
               $.ajax(ajaxData);
           }
        },

        updateRegistryRows = function(orderDetailIds, invoiceData) {
            PRODUCTION_ORDERS_REGISTRY.find("tbody>tr").each(function() {
                if(orderDetailIds.includes($(this).data("id").toString())) {
                    $(this).addClass("last-entered-row");
                    $(this).find("[name='orderDetail[]']").prop("checked", false);
                    var invoiceLink = $(this).find(".order-detail-get-invoice");
                    invoiceLink.attr('data-invoice-id', invoiceData.invoiceId);
                    invoiceLink.attr('data-invoice-number', invoiceData.invoiceNumber);
                    invoiceLink.attr('data-is-link-active', invoiceData.invoiceId ? 'true' : 'false');
                    invoiceLink.text(invoiceData.invoiceNumber);
                    invoiceLink.addClass('order-product-invoice-link');
                } else {
                    $(this).removeClass("last-entered-row");
                }
            });
        },

        toggleUpdateInvoiceButtonActivity = function() {
            var orderDetailsChecked = $("[name='orderDetail[]']:checked").size() > 0;
            $(".update-invoice-number").attr("disabled", !orderDetailsChecked);
        },

        filterInProgress = function(e) {
            if($(this).is(".active")) {
                $("#criteria_inProgress_status").val(null);
            } else {
                $("#criteria_inProgress_status").val(ORDERS_IN_PROGRESS_STATUS);
            }
            fpSearchRegistry.call(this, e);
        },

        filterFinished = function(e) {
            if($(this).is(".active")) {
                $("#criteria_finished_status").val(null);
            } else {
                $("#criteria_finished_status").val(ORDERS_FINISHED_STATUS);
            }
            fpSearchRegistry.call(this, e);
        },

        filterDelivered = function(e) {
            if($(this).is(".active")) {
                $("#criteria_delivered_status").val(null);
            } else {
                $("#criteria_delivered_status").val(ORDERS_DELIVERED_STATUS);
            }
            fpSearchRegistry.call(this, e);
        },

        filterSuspended = function(e) {
            if($(this).is(".active")) {
                $("#criteria_suspended_status").val(null);
            } else {
                $("#criteria_suspended_status").val(ORDERS_SUSPENDED_STATUS);
            }
            fpSearchRegistry.call(this, e);
        },

        filterUnsettled = function(e) {
            if($(this).is(".active")) {
                $("[name='criteria.noInvoiceNumber']").val("false");
            } else {
                $("[name='criteria.noInvoiceNumber']").val("true");
            }

            fpSearchRegistry.call(this, e);
        },

        switchToDetailedView = function(e) {
            if($(this).is(".active")) {
                $("[name='criteria.orderDetailedView']").val("false");
            } else {
                $("[name='criteria.orderDetailedView']").val("true");
            }
            sessionStorage.setItem("orders-view-url", $(this).data("url"));
            replaceOrdersViewUrl();
            fpOpenView.call(this, e);
        };

        deliverOrder = function() {
            if(confirm(i18n('confirmAction'))) {
                var $this = $(this);
                var ajaxData = $.extend({},iAjaxData,{
                    url : $(this).data("url"),
                    type: 'GET',
                    success: function(data, textStatus, jqXHR) {
                        if(manageExceptions.call(this, jqXHR, data)) {
                            return false;
                        }
                        updateOrderStatusToDelivery($this);
                        alertify.success(i18n('OrderDelivered'));
                    }
                });
                $.ajax(ajaxData);
            }
        },

        updateOrderStatusToDelivery = function($this) {
            $this.parent().prev().html(i18n('order.status.DELIVERED'));
        },

        getInvoiceAsFile = function() {
            if($(this).data("is-link-active")){
                var form = getOrdersRegistryForm(this);
                iShowMask(form);
                var ajaxData=$.extend({},iAjaxData,{
                    _this : form,
                    dataType: 'binary',
                    url : $(this).data("url"),
                    data: {
                        invoiceId: $(this).data("invoice-id"),
                        invoiceNumber: $(this).data("invoice-number")
                    },
                    success: startDownloadingInvoicePdf
                });
                $.ajax(ajaxData);
            }
        },

        startDownloadingInvoicePdf = function(data, textStatus, jqXHR) {
            iHideMask(this._this);
            var blob = new Blob([data], {type: "application/pdf"});
            var fileName = jqXHR.getResponseHeader("filename");
            saveAs(blob, fileName);
        };

        bindInProgressOrdersEvents = function() {
            $(this).find(".order-product-raw-material-link").click(fpOpenLink);
            $(this).find(".order-detail-get-invoice").click(getInvoiceAsFile);
            $(this).find(".iAttachmentLink").click(fpOpenAttachmentLink);
            $(this).find(".order-detail-sizes-button").click(fpOpenLink);
            $(this).find("[name='orderDetail[]']").change(toggleUpdateInvoiceButtonActivity);
            $(this).find(".order-detail-preview").click(showOrderPreview);
            $(this).find(".remove-priority").click(removePriority);
            toggleUpdateInvoiceButtonActivity();
            $(PRODUCTION_ORDERS_REGISTRY).showPlannedRealization();
            bindExcelExport();
        },

        bindOrdersToDeliverEvents = function() {
            $(this).find(".deliver-order").click(deliverOrder);
        },

        showOrderPreview = function(e) {
            $(this).closest("tbody").find(".last-entered-row").removeClass("last-entered-row");
            $(this).closest("tr").addClass("last-entered-row");
            fpOpenContext.call(this, e);
        },

        bindSortableOrders = function(registry) {
            $(registry).find(".production-orders-sortable > tbody").sortable({
                handle: ".order-drag-drop",
                placeholder: "sortable-placeholder",
                update: updateOrderPriorities,
                scroll: true,
                start: function(event, ui) {
                    ui.placeholder.height(ui.item.height());
                }
            }).disableSelection();
        },

        updateOrderPriorities = function(e, ui) {
            ui.item.data("pinned", "true");
            ui.item.find(".remove-priority").show();
            var priorities = {};
            $(this).find("tr").each(function() {
                var orderId = $(this).data("id");
                var isPinned = $(this).data("pinned");
                if(!priorities[orderId] && isPinned) {
                    priorities[orderId] = $(this).index()+1;
                }
            });

            var ajaxData = $.extend({},iAjaxData,{
                url : $(this).data("updatepriority"),
                data: {
                    priorities: JSON.stringify(priorities)
                }
            });
            $.ajax(ajaxData);
        },

        removePriority = function(e) {
            if(confirm(i18n("ConfirmRemovePriority"))) {
                e.stopPropagation();
                var form = getOrdersRegistryForm(this);
                iShowMask(form);
                var $elem = $(this);
                var ajaxData = $.extend({}, iAjaxData, {
                    url: $elem.data("url"),
                    beforeSend: function() {},
                    success: function(data, textStatus, jqXHR) {
                        $elem.remove();
                        iHideMask(form);
                    }
                });
                $.ajax(ajaxData);
            }
        },

        bindExcelExport = function() {
            new ExcelExport().bind();
        },

        getOrdersRegistryForm = function(elem) {
            return $(elem).closest(".factory-view").find("form").eq(0);
        }

        return this.each(function() {
            $(this).find(".fpSearch").keyup(fpSearchRegistry);
            $(this).find(".filtering-finished").click(filterFinished);
            $(this).find(".filtering-inProgress").click(filterInProgress);
            $(this).find(".filtering-suspended").click(filterSuspended);
            $(this).find(".filtering-delivered").click(filterDelivered);
            $(this).find(".filtering-unsettled").click(filterUnsettled);
            $(this).find(".switch-orders-detailed-view").change(switchToDetailedView);
            $(this).find(".fpSort").click(fpSortRegistry);
            $(this).find(".fpNavigate").click(fpRegistryMoreResults);
            $(this).find(".fpEditIcon, .fpAddButton").click(fpOpenLink);
            $(this).find(".update-invoice-number").click(updateInvoiceNumber);
            $(this).find(".production-orders-registry").on("registry-reloaded", bindInProgressOrdersEvents);
            $(this).find(".orders-to-deliver-registry").on("registry-reloaded", bindOrdersToDeliverEvents);
            $(this).find(".fpChooseDatePopover").datepicker({
                dateFormat: "yy-mm-dd"
            }).change(fpSearchRegistry);
            bindExcelExport();
            bindSortableOrders(this);
            bindInProgressOrdersEvents.call(this);
            bindOrdersToDeliverEvents.call(this);
            getSummary($("#ordersTable").closest("form"));
        });
    }

}(jQuery));