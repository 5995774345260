/***************************** Obsługa przełączania widoków ******************************/

var LOADING = '<div class="factory-view-loading "><img src="/public/images/preloader.gif"/></div>';

var preventReloadOnHashChange = false;
var urlWithNoHashChange = ["/orderdetailoperations/movetomachine",
    "/orderdetails/updatepriorities",
    "/productionorders/updatepriorities",
    "/productionorders/resetpriority",
    "/schedulecontroller/getorderrealizationdates",
    "/orderdetails/orderdetails",
    "/schedulecontroller/getproductrealizationdates",
    "/productionreportcontroller/createreportsummary",
    "/productionreportcontroller/createreport",
    "/productionreportcontroller/exporttoxls",
    "/productionreportdetailedcontroller/createdetailedreportsummary",
    "/productionreportdetailedcontroller/createreportdetailed",
    "/productionreportdetailedcontroller/exportdetailedtoxls",
    "/machines/machines",
    "/machines/savemachine",
    "/operations/operations",
    "/departments/departments",
    "/departments/savedepartment",
    "/operations/saveoperation",
    "/productionprocesses/productionprocesses",
    "/productionprocesses/saveproductionprocess",
    "/clients/clientsregistry",
    "/products/products",
    "/products/rawmaterialsstock",
    "/users/users",
    "/users/saveuser",
    "/orderdetailoperations/movetooperation",
    "/orderdetails/resetpriority",
    "/loadproductsfromcatalogue",
    "/announcements/saveannouncement",
    "/announcements/announcements",
    "/orderdetails/assignforeman",
    "/orderdetails/exporttoxls",
    "/orderdetails/updateinvoicenumberbyorderdetailids",
    "/orderdetails/getinvoice",
    "/products/createrawmaterialssummary",
    "/constructiondetails/indexfromordercontext",
    "/constructiondetails/loadproductsfromcatalogue",
    "/orderdetails/getorderscalculations",
    "/products/getreleasesstatsbymaterialid",
    "/products/getreceiptstatsbymaterialid"
];

var redirectArray = [
        {urlFrom: "constructiondetails/constructiondetail", urlTo: "/constructiondetails/index"}
    ];

/* Żądanie odpowiedniego widoku */
var fpOpenUrl = function(url) {
    $(".iMain > .fpContent").html(LOADING);

	var ajaxData = $.extend({},iAjaxData,{
		url : url,
		success: fpOpenViewSuccess,
		error : function(jqXHR, textStatus, errorThrown) {
            fpOpenUrl("/app/loadplanningboard")
            changeHash("/app/loadplanningboard");
        }
	});
	$.ajax(ajaxData);
}

var fpOpenView = function(event) {
    $(".iMain > .fpContent").html(LOADING);

	var ajaxData = $.extend({},iAjaxData,{
		url : $(this).data("url"),
		success: fpOpenViewSuccess
	});
	$.ajax(ajaxData);	//żądanie załadowania danych do widoku
}

var fpOpenExternalView = function(event) {
	var ajaxData = $.extend({},iAjaxDataJSON,{
		url : $(this).data("url"),
		success: fpOpenExternalViewSuccess
	});
	$.ajax(ajaxData);
}

var fpOpenExternalViewSuccess = function(data, textStatus, jqXHR) {
	if(manageExceptions.call(this, jqXHR, data)) {
    return false;
  }
  window.open(data.url, '_blank');
}

var fpOpenLink = function(event) {
    $(".iMain > .fpContent").html(LOADING);

    var criteria = getFormCriteria(this);
    saveLastEditedRow(event.srcElement);

    var ajaxData = $.extend({},iAjaxData,{
        url : $(this).data("url"),
        success: function(data, textStatus, jqXHR) {
            fpOpenViewSuccess.call(this, data, textStatus, jqXHR);
            setCriteria(criteria);
            if(!criteria.save) {
                $(".fpSearch").first().trigger(jQuery.Event( 'keyup', { keyCode: $.ui.keyCode.ENTER } ));
            }
        }
    });
    $.ajax(ajaxData);
}

var fpOpenContext = function(event, options) {
    var $modalSidebar = $("#modal-sidebar");
    var $modalSidebarBody = $modalSidebar.find(".modal-body");

    if(options && options.isWide === true) {
        $modalSidebar.addClass("wide");
    } else {
        $modalSidebar.removeClass("wide");
    }

    $modalSidebar.find(".modal-title").html($(this).data("title"));
        $modalSidebar.data("sender", this);
        $modalSidebar.modal({
          keyboard: true,
          backdrop: true
        });

    $modalSidebarBody.html(LOADING);

    var ajaxData = $.extend({},iAjaxData,{
    	_body : $modalSidebarBody,
    	url : $(this).data("url"),
    	success: fpOpenContextSuccess
    });
    $.ajax(ajaxData);	//żądanie załadowania danych do okna
}

var fpOpenContextSuccess = function(data, textStatus, jqXHR){
    manageExceptions.call(this, jqXHR, data);
	$(this._body).html(data);
}

var selectLastEnteredRow = function(row) {
    var $dataTable = $(row).closest(".fpDataTable");
        $dataTable
           .find(".last-entered-row").removeClass("last-entered-row")

        $(row).addClass("last-entered-row");
}

var fpOpenAttachmentLink = function(event) {
    selectLastEnteredRow($(event.srcElement).closest("tr"));
}

var fpCloseModalSidebar = function() {
    $("#modal-sidebar").modal('hide');
}


var toggleSidebar = function(event) {
    $(".fpSidebar").toggleClass('small');
    if ($(event.srcElement).hasClass('menuLink')) {
        $(".fpSidebar").addClass('small');
    }

    var isSmall= $(".fpSidebar").hasClass('small');
    if(isSmall){
        $('.menuLink').tooltip('enable');
    }else{
        $('.menuLink').tooltip('disable');
    }
}

function openToSettle() {
    $(".iMain > .fpContent").html(LOADING);
    var criteria = getFormCriteria(this);

    var ajaxData = $.extend({},iAjaxData,{
        url : $(this).data("url"),
        data: criteria,
        success: function(data, textStatus, jqXHR) {
            fpOpenViewSuccess.call(this, data, textStatus, jqXHR);
            setCriteria(criteria);
            if (!criteria.save) {
                $(".fpSearch").first().trigger(jQuery.Event('keyup', {keyCode: $.ui.keyCode.ENTER}));
            }
        }
    });
    $.ajax(ajaxData);
}

/* Załadowanie odpowiedniego widoku */
var fpOpenViewSuccess = function(data, textStatus, jqXHR){
    manageExceptions.call(this, jqXHR, data);
	$(".iMain > .fpContent").html(data);
	$("*[title]:not([data-toggle='popover'])").tooltip();
}

/* Przeładowanie części okna modalnego */
var iReloadModalWindow = function(partId) {
    var $modalWindowPart = $(".factory-view").find("[id='" + partId + "']").eq(0);
    if($modalWindowPart.size() > 0) {
        var ajaxData = $.extend({},iAjaxData,{
            _window : $modalWindowPart,
            url : $modalWindowPart.data("reload"),
            success: iOpenModalWindowSuccess
        });
        $.ajax(ajaxData);	//żądanie załadowania danych do okna
    }
}

var iOpenModalWindowSuccess = function(data, textStatus, jqXHR){
    manageExceptions.call(this, jqXHR, data);
	$(this._window).removeClass("iLoading").html(data);
}

/* Maska */
var iShowMask = function(element) {
    $(element).mask(i18n('Loading'));
}

var iHideMask = function(element) {
    setTimeout(function() {$(element).unmask()}, 300);
}

var locationHashChanged = function() {
    if (!preventReloadOnHashChange){
        fpOpenUrl(redirectHash(location.hash.substring(1)));
    } else {
        preventReloadOnHashChange = false;
    }
}

var changeHash = function(url) {
    if(!urlWithNoHashChange.includes(url)){
        preventReloadOnHashChange = true;
        window.location.hash = url;
    }
    return url;
}

var redirectHash = function(url) {
    if(!url) {
        url = "/";
    }

    redirectArray.forEach(function (row) {
        if (url.includes(row.urlFrom)){
            url = row.urlTo;
            return changeHash(url);
        }
    })
    return url;
}

var openPageByHash = function(url) {
    if (url && url != ""){
        fpOpenUrl(redirectHash(url));
    }
};

var replaceOrdersViewUrl = function() {
    var defaultUrl = $(".menuOrdersView").data("url");
    var ordersViewUrl = sessionStorage.getItem("orders-view-url");
    $(".menuOrdersView").data("url", ordersViewUrl ? ordersViewUrl : defaultUrl);
};