(function($) {

    $.fn.loadProcess = function() {

    loadData = function(data) {
        var $spreadsheet = $(this._this);
        var $spreadsheetRows = $spreadsheet.find("ul > li.operation-item");

        if(data.length > 0) {
            $spreadsheet.trigger("reset-operations");
            fillSpreadsheet(data, $spreadsheet);
        } else {
            alertify.warning(i18n('NoOperations'));
        }
    },

    fillSpreadsheet = function(data, $spreadsheet) {
        for(var operation in data) {
            $spreadsheet.trigger("load-operation", [data[operation]]);
        }
    },

    getProcessOperations = function() {
        var $spreadsheet = $(".production-process-spreadsheet").eq(0);
        var ajaxData = $.extend({},iAjaxDataJSON,{
            _this: $spreadsheet,
            url : $(this).data("url"),
            data: {
                id: $(this).val()
            },
            success: loadData
        });
        $.ajax(ajaxData);
        
    },

    bindSelect = function(contextElem) {
        $(contextElem).parent()
            .prev()
            .prev()
            .find(".iSpreadsheetSelectTemplate")
            .change(getProcessOperations);
    };

	return this.each(function() {
        bindSelect(this);
	});
}
}(jQuery));