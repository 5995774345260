/**
 * @author visix
 */

(function($) {

    $.fn.announcement = function() {

        var UPLOAD_FORM = $("form.upload-form");

        var showExistingFiles = function(dropzone) {

            $(".attachments-data").children().each(function() {
                var mockFile = {name: $(this).data("name"), status: "mocked"};
                dropzone.displayExistingFile(mockFile,
                $(this).data("href"), null, null, false);
                dropzone.files.push(mockFile);
                mockFile._removeLink.innerHTML = i18n("announcement.removeFile");
            });
        },

        removeFiles = function(filesToRemove, dropzoneField) {
            var ajaxData = $.extend({}, iAjaxData, {
                url: $(dropzoneField.element).attr("data-delete-url"),
                data: {
                    attachmentNames: filesToRemove,
                    announcementId: $('#announcement_id').val()
                },
                success: function() {
                    alertify.success(i18n('announcement.attachmentsDeleted'));
                }
            });
            $.ajax(ajaxData);
        };

        return this.each(function() {
            $(this).find(".fpSaveButton").unbind("click").click(fpSaveForm);
            $(this).find(".fpDeleteButton").unbind("click").click(fpDeleteForm);
            $(this).find(".fpBackButton").unbind("click").click(fpOpenLink);
            $(this).find("#dropzone").dropzone({
                url: UPLOAD_FORM.attr("action"),
                addRemoveLinks: true,
                acceptedFiles: 'image/*',
                autoProcessQueue: false,
                parallelUploads: 100,
                maxFiles: 100,
                uploadMultiple:true,

                init: function() {
                    var dropzoneField = this;
                    var filesToRemove = [];
                    showExistingFiles(dropzoneField);

                    $(".fpSaveButton").unbind("click").click(function (e) {
                        iShowMask(UPLOAD_FORM);
                        if(filesToRemove.length > 0) {
                            removeFiles(filesToRemove, dropzoneField);
                            filesToRemove = [];
                        }

                        if(dropzoneField.getQueuedFiles().length > 0) {
                            e.preventDefault();
                            dropzoneField.processQueue();
                        } else {
                            fpSaveForm.call(dropzoneField.element);
                        }

                    });

                    this.on("removedfile", function (file) {
                        if(file.status != "added") {
                            filesToRemove.push(file.name);
                        }
                    });

                    this.on("addedfile", function(file) {
                        file._removeLink.innerHTML = i18n("announcement.removeFile");
                        if (this.files.length) {
                            var len = dropzoneField.files.length;
                            for (var i = 0; i<len-1; i++) {
                                if(dropzoneField.files[i].name === file.name) {
                                    dropzoneField.removeFile(file);
                                    break;
                                }
                            }
                        }
                    });

                    this.on("sendingmultiple", function(data, xhr, formData) {
                        formData.append("announcement.id", $('#announcement_id').val());
                        formData.append("announcement.name", $('#announcement_name').val());
                    });

                    this.on("successmultiple", function(files, response) {
                      alertify.success(i18n('announcement.uploadSuccessful'));
                      iHideMask(UPLOAD_FORM);
                      $(".fpBackButton").trigger("click");
                    });

                    this.on("errormultiple", function(files, response) {
                      alertify.success(i18n('announcement.uploadError'));
                      iHideMask(UPLOAD_FORM);
                    });

                }
            });
        });
    }

}(jQuery));