/**
 * @author visix
 */

(function($) {

    $.fn.operations = function() {

        var saveMachine = function() {
            var $form = fpGetForm.call(this);

            iShowMask($form);
            var ajaxData=$.extend({},iAjaxData,{
                _this : $form,
                action: ACTIONS.SAVE,
                data : iDefaultFormDataGenerator({tag:$form}),
                url : $(this).data("url"),
                success: fpFormSuccess
            });
            $.ajax(ajaxData);
        }

        return this.each(function() {
            $(".fpSaveButton").click(fpSaveForm);
            $(".fpDeleteButton").click(fpDeleteForm);
            $(".fpBackButton").click(fpOpenLink);
            $(".fpSaveAndCreateMachineButton").click(saveMachine);
        });
    }

}(jQuery));