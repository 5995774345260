(function($) {

    $.fn.material = function() {

        var loadStatsTabSuccess = function (data, textStatus, jqXHR) {
            manageExceptions.call(this, jqXHR, data);
      	    $(this._this).find(".statistics").html(data);
        };

        var loadStatsTab = function () {
            var tab = this;
            var url = $(tab).data("url");
            var ajaxData=$.extend({},iAjaxData,{
                url : url,
                _this: $("." + $(tab).data("tab")),
                success: loadStatsTabSuccess
            });
            $.ajax(ajaxData);
        };

        var openDetails = function(e) {
            fpOpenContext.call(this, e, {isWide: true});
        }

        $(document).ready(function() {
            showUpload = function() {
                iNewWindow($(this).data("url"),"attachment", 750, 200);
            };
            $(".fpSaveButton").click(fpSaveForm);
            $(".fpDeleteButton").click(fpDeleteForm);
            $(".fpBackButton").click(fpOpenLink);
            $("form.autoFill").autoFill();
            createDatePicker(".fpChooseDatePopover");
            new ClientAutocomplete().bind();
            $(".iUploadLink").click(showUpload);
            $(".iDeleteFileLink").click(iDeleteFile);
            $(".fpFormTabs").tabs();
            $(".stats-tab").click(loadStatsTab);
            $(".stock-operations").click(openDetails);
        });
    }
}(jQuery));