/**
 * @author visix
 */

(function($) {

    $.fn.addStockOperation = function() {

        var clearOldEntries = function($spreadsheet) {
            $spreadsheet.find(".form-group.toRemove").remove();
        },

        loadOrderProducts = function(data) {
            var $spreadsheet = $(this._this);
            var $spreadsheetRows = $spreadsheet.find(".form-group:not(:has(.iSpreadsheetAddRow))");

            $spreadsheetRows.addClass("toRemove");

            if(data.length > 0) {
                fillSpreadsheet(data, $spreadsheet);
                clearOldEntries($spreadsheet);
            } else {
                alertify.warning(i18n('NoOrderProducts'));
            }
        },

        fillSpreadsheet = function(data, $spreadsheet) {
            for(var product in data) {
                $spreadsheet.find(".iSpreadsheetAddRow").click();
                var $row = $spreadsheet.find(".form-group").last().prev();

                $row.removeClass("toRemove")
                $row.find("input[name='itemName']").val(data[product].name);
                $row.find("input[name='itemId']").val(data[product].id);
                $row.find("input[name='orderDetailId']").val(data[product].orderDetailId);
                $row.find("input[name='amount']").val(data[product].amount);
            }
        },

        getOrderProducts = function(orderId) {
            var $orderAutocomplete = $(".order-autocomplete");
            var $spreadsheet = $(".spreadsheet-form[data-name='stockReleaseItems']").eq(0);
            var ajaxData = $.extend({},iAjaxDataJSON,{
                _this: $spreadsheet,
                url : $orderAutocomplete.data("load-order-url"),
                data: {
                    orderId: orderId
                },
                success: loadOrderProducts
            });
            $.ajax(ajaxData);
        },

        setNotes = function(order) {
            $("[name='notes']").val(i18n('NewOrderStockRelease') + ' ' + order);
        },

        getOrdersAutocomplete = function(){
           return $.extend({},Autocomplete.common, {
                select: function( event, ui ) {
                   this.value = ui.item.label;
                   $(this).parent().find("[name='orderId']").val(ui.item.value).trigger("change");
                   getOrderProducts(ui.item.value);
                   setNotes(this.value);
                   return false;
                }
            });
        },

        bindAutocompleteMaterialsAutocomplete = function(context) {
            $(context).find("[name='itemName']").materialAutocomplete();
        },

        bindAutocompleteOrdersAutocomplete = function(context) {
            $(".order-autocomplete").autocomplete(getOrdersAutocomplete());
        }

        return this.each(function() {
            $(this).find(".fpSaveButton").click(fpSaveForm);
            $(this).find(".fpBackButton").click(fpOpenLink);

            var $newStockReleaseSpreadsheet = $(this).find(".new-stock-operation-form .iSpreadsheet");
            $newStockReleaseSpreadsheet.spreadsheet(bindAutocompleteMaterialsAutocomplete);

            bindAutocompleteMaterialsAutocomplete($newStockReleaseSpreadsheet);
            bindAutocompleteOrdersAutocomplete(this);
        });

    }

}(jQuery));