/**
 * @author visix
 */

(function($) {

    $.fn.materialReleaseAutocomplete = function() {

        getMaterialsAutocomplete = function(){
           return $.extend({},Autocomplete.common, {
                source: function (request, response) {
                    $.ajax({
                        url: $(this.element).attr("data-url"),
                        dataType: "json",
                        data: {
                            term: request.term
                        },
                        success: function (data) {
                            response($.map(data, function (item) {
                                return {
                                    label: (item.name + ' [' + item.unit + ']'),
                                    value: item.id,
                                    unit: item.unit,
                                    minimalAmount: item.minimalAmount,
                                    actualAmount: item.actualAmount,
                                    deliveryAmount: item.deliveryAmount,
                                    stockAmounts: item.stockAmounts
                                };
                            }));
                        }
                    });
                },
                select: function( event, ui ) {
                    this.value = ui.item.label;
                    var $target = Autocomplete.common.getTarget(this);
                    if($target) {
                        $(this).trigger("change");
                        $target.val(ui.item.value).trigger("change");
                    } else {
                        console.log("Could not find Autocomplete.common target element");
                    }
                    loadDeliveryAmount($target, ui.item);
                    return false;
                },
            })
        },

        loadDeliveryAmount = function($target, response) {

            var $row = $target.closest(".form-group");

            var deliveryAmountInput = $row.find("[data-autocomplete-amount='deliveryAmount']");
            if(deliveryAmountInput.size() > 0) {
                deliveryAmountInput.val(response.deliveryAmount);
            }

            var $sourceStock = $row.find("select[name='sourceStockId']");

            $sourceStock.empty();

            response.stockAmounts.forEach(function (stock) {
                var optionText = stock.name + " (" + stock.amount + ")";
                var option = $("<option></option>").val(stock.id).text(optionText);
                $sourceStock.append(option);
            });

            checkStockLevels();
        },
        markMaterialsUnderMin = function(list, item){
            var listItem = $("<li></li>")
            .data("item.autocomplete", item)
            .append("<a>" +  item.label + " ( " + item.actualAmount + " " + item.unit + " )</a>")
            .appendTo(list);

            if (parseFloat(item.actualAmount) < parseFloat(item.minimalAmount)) {
                listItem.addClass("raw-material-under-min");
            }

            return listItem;
        },

        bindAutocomplete = function(context) {
            var $materialsAutocomplete = $(context).autocomplete(getMaterialsAutocomplete());

            if($materialsAutocomplete.size() > 0) {
                $materialsAutocomplete.each(function() {
                    $(this).data("ui-autocomplete")._renderItem = markMaterialsUnderMin;
                });
            }
            $(context).on('change', "input[name='itemName']", function() {
                checkStockLevels();
            });
        }

        checkStockLevels = function() {
            var rowNumbers = $(".iSpreadsheet .form-group").not(':last').map(function() {
                var $row = $(this);
                var itemName = $row.find("input[name='itemName']").val();
                var sourceStockIdCount = $row.find("select[name='sourceStockId'] option").length;
                if (itemName !== "" && sourceStockIdCount === 0) {
                    return itemName;
                }
            }).get();

            if (rowNumbers.length > 0) {
                $(".product-zero-amount-message").show();
                $(".product-zero-amount-message").find(".alert-info").text(i18n('ProductZeroAmount') + rowNumbers.join(", ")).show();
            } else {
                $(".product-zero-amount-message").hide();
            }
        };

        return this.each(function() {
            bindAutocomplete(this);
        });

    }

}(jQuery));