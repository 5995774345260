/**
 * Created by visix on 25.05.2017.
 */

(function($) {

    $.fn.orderDetailPreview = function() {

        var ORDER_PREVIEW_TABLE = $(".order-preview-table");
        var MACHINES_LIST = $(".machinesList");
        var SEARCH_PRODUCTS_CONTAINER = $(".order-product-search");

        editOrder = function () {
            fpCloseModalSidebar();
            $(".iMain > .fpContent").html(LOADING);
            $.fn.desktop().showOrder.call(this);
        };

        openLink = function (e) {
            fpCloseModalSidebar();
           fpOpenLink.call(this, e);
        };

        updateForeman = function() {
            var ajaxData=$.extend({},iAjaxData,{
                url : $(this).data("url"),
                data: {
                    userId: $(this).find("option:selected").val(),
                    orderDetailOperationId: $(this).data("orderdetailoperationid")
                },
                success: function(data, textStatus, jqXHR) {
                    filterList(MACHINES_LIST, "li.machine-order-detail", SEARCH_PRODUCTS_CONTAINER);
                    alertify.success(i18n('orderDetailPreview.foremanChanged'));
                    fpCloseModalSidebar();
                }
            });
            $.ajax(ajaxData);
        };

        return this.each(function() {
            $(".order-product-raw-material-link").click(openLink);
            $(".fpEditButton").click(editOrder);
            $(".changeForeman").change(updateForeman);
            $(ORDER_PREVIEW_TABLE).showPlannedRealization();
        });
    }
}(jQuery));