/**
 * @author visix
 */

(function($) {

    $.fn.materials = function() {

        var WASTE = "WASTE";
        var TEMPLATES_URL = {
            "PRODUCT": "products/products",
            "SEMI_PRODUCT": "products/semiproducts",
            "WASTE": "products/wastes",
            "RAW_MATERIAL": "products/rawmaterials",
        };

        updateAmount = function () {
           var _this = $(this);
           var amount = prompt(i18n("products.updateAmount"));
            if(amount) {
                var ajaxData = $.extend({},iAjaxData,{
                    url : _this.data("url"),
                    data: {
                        amount: parseFloat(amount.replace(',', '.'))
                    },
                    success: function(data, textStatus, jqXHR) {
                        if(manageExceptions.call(this, jqXHR, data)) {
                            return false;
                        }
                        _this.next().text(data.actualAmount);
                        _this.parent().parent().find(".available-amount").text(data.availableAmount);
                        _this.parent().parent().find(".assigned-amount").text(data.assignedAmount);
                        alertify.success(i18n("ChangeSaved"));
                    },
                    error: function() {
                        alertify.error(i18n('exception.invalidData'))
                    }
                });
                $.ajax(ajaxData);
            }
        },

        filterByCategory = function(e) {
            $(".iMain > .fpContent").html(LOADING);

            var criteria = getFormCriteria(this);
            var newValue = $(this).find("[name='materialCategory']").val();
            $("[name='criteria.materialCategory']").val(newValue);
            var categoryId = $(this).find("[name='materialCategory']").data("categoryid");
            var url = TEMPLATES_URL[newValue];
            var ajaxData = $.extend({},iAjaxData,{
                url : url,
                data: criteria,
                success: function(data, textStatus, jqXHR) {
                    fpOpenViewSuccess.call(this, data, textStatus, jqXHR);
                }
            });
            $.ajax(ajaxData);
        },

        bindEvents = function() {
            $(this).find(".update-material-amount").click(updateAmount);
            $(this).find(".sub-stocks").click(showSubStocks);
            $(this).find(".material-order-reservations").click(showMaterialReservations);
            $(this).find(".fpQrcode").click(showQrcode);
        };

        addProduct = function(event) {
            $(".iMain > .fpContent").html(LOADING);

            var criteria = getFormCriteria(this);

            saveLastEditedRow(event.srcElement);

            var ajaxData = $.extend({},iAjaxData,{
                url : $(this).data("url"),
                data: {
                    materialCategoryId: $(this).closest(".factory-view").find(".active > [name='materialCategory']").data("categoryid")
                },
                success: function(data, textStatus, jqXHR) {
                    fpOpenViewSuccess.call(this, data, textStatus, jqXHR);
                    setCriteria(criteria);
                    if(!criteria.save) {
                        $(".fpSearch").first().trigger(jQuery.Event( 'keyup', { keyCode: $.ui.keyCode.ENTER } ));
                    }
                }
            });
            $.ajax(ajaxData);
        },

        showMaterialReservations = function(e) {
            $(this).closest("tbody").find(".last-entered-row").removeClass("last-entered-row");
            $(this).closest("tr").addClass("last-entered-row");
            fpOpenContext.call(this, e);
        },

        showSubStocks = function(e) {
            $(this).closest("tbody").find(".last-entered-row").removeClass("last-entered-row");
            $(this).closest("tr").addClass("last-entered-row");
            fpOpenContext.call(this, e);
        },

        importFromBaseLinker = function(){
            if(confirm(i18n('confirmAction'))) {
                var $form = $(this).closest(".factory-view").find("form").eq(0).find(".fpDataTable");
                iShowMask($form);
                var ajaxData = $.extend({},iAjaxData,{
                    _this: $form,
                    url : $(this).data("url"),
                    success: importFromBaseLinkerSuccess
                });
                $.ajax(ajaxData);
            }
        },

        importFromBaseLinkerSuccess = function (data, textStatus, jqXHR) {
            if(manageExceptions.call(this, jqXHR, data)) {
                return false;
            } else {
                var $form = $(".factory-view > form");
                reloadTable($form);
                alertify.success(i18n('baselinker.importSuccess'));
            }
        },

        showQrcode = function() {
            iNewWindow($(this).data("url"),"qrcode", 600, 400);
        },

        filterPlanned = function(e) {
            if($(this).is(".active")) {
                $("[name='criteria.onlyUnderMinAmount']").val(false);
            } else {
                $("[name='criteria.onlyUnderMinAmount']").val(true);
            }

            fpSearchRegistry.call(this, e);
        };

        return this.each(function() {
            $(this).find(".fpSearch").keyup(fpSearchRegistry);
            $(this).find(".fpSearchCombo").change(fpSearchRegistry);
            $(this).find(".fpSort").click(fpSortRegistry);
            $(this).find(".fpNavigate").click(fpRegistryMoreResults);
            $(this).find(".fpEditIcon, .fpStockOperations, .fpSubStocks").click(fpOpenLink);
            $(this).find(".fpAddButton").click(addProduct);
            $(this).find(".materials-registry").on("registry-reloaded", bindEvents);
            $(this).find(".materials-category-choice > .filtering-radio").click(filterByCategory);
            $(this).find(".filtering-undermin").change(filterPlanned);
            $(this).find(".fpImportButton").click(importFromBaseLinker);
            getSummary($(".materials-registry").closest("form"));
            bindEvents.call(this);
        });

    }

}(jQuery));
