/**
 * Created by visix on 25.05.2017.
 */

(function($) {

    $.fn.orderPreview = function() {

        var ORDER_PREVIEW_TABLE = $(".order-preview-table");

        var STATUS_MESSAGE = {
          "SUSPENDED": "OrderSuspended",
          "FINISHED": "OrderFinished",
          "IN_PROGRESS": "OrderInProgress"
        };

        editOrder = function () {
            $(".iMain > .fpContent").html(LOADING);
            $.fn.desktop().showOrder.call(this);
        };

        openLink = function (e) {
           fpOpenLink.call(this, e);
        };

        changeStatus = function() {
            if(confirm(i18n('confirmAction'))) {
                var $this = $(this);
                var ajaxData = $.extend({},iAjaxData,{
                    url : $(this).data("url"),
                    type: 'GET',
                    success: function(data, textStatus, jqXHR) {
                        if(manageExceptions.call(this, jqXHR, data)) {
                            return false;
                        }
                        changeSuccess($this.find("input").attr("value"));
                    }
                });
                $.ajax(ajaxData);
            } else {
                return false;
            }
        };

        changeSuccess = function(status) {
            alertify.success(i18n(STATUS_MESSAGE[status]));
            $(".fpBackButton").trigger("click");
        };

        copyOrder = function() {
            if(confirm(i18n('confirmCopyOrder'))) {
                var $this = $(this);
                var ajaxData = $.extend({},iAjaxData,{
                    url : $(this).data("url"),
                    type: 'GET',
                    success: function(data, textStatus, jqXHR) {
                        if(manageExceptions.call(this, jqXHR, data)) {
                            return false;
                        }
                        copySuccess();
                    }
                });
                $.ajax(ajaxData);
            } else {
                return false;
            }
        };

        copySuccess = function(status) {
            alertify.success(i18n("order.copySaved"));
            $(".fpBackButton").trigger("click");
        };

        sendSummaryToExternalSystem = function() {
            if(confirm(i18n('confirmAction'))) {
                var $this = $(this);
                var ajaxData = $.extend({},iAjaxData,{
                    url : $(this).data("url"),
                    type: 'GET',
                    success: function(data, textStatus, jqXHR) {
                        if(manageExceptions.call(this, jqXHR, data)) {
                            return false;
                        }
                        sendSummaryToExternalSystemSuccess();
                    }
                });
                $.ajax(ajaxData);
            } else {
                return false;
            }
        };

        sendSummaryToExternalSystemSuccess = function(status) {
            alertify.success(i18n("order.summaryToExternalSystemSent"));
        };

        return this.each(function() {
            new ExcelExport().bind();
            $(".order-products .fpFormTabs").tabs();
            $(".show-sizes, .show-sub-elements, .order-product-raw-material-link").click(openLink);
            $(".fpEditButton").click(editOrder);
            $(".finish-order, .suspend-order, .in-progress-order").click(changeStatus);
            $(".fpBackButton").click(fpOpenLink);
            $(".fpCopyButton").click(copyOrder);
            $(".fpSendSummaryToExternalSystemButton").click(sendSummaryToExternalSystem);
            $(".print-labels").click(function() {
                iNewWindow($(this).data("url"),"qrcode", 1200, 800);
            });
            $(".edit-production-process").click(fpOpenView);
            $(ORDER_PREVIEW_TABLE).showPlannedRealization();


        });

    }

}(jQuery));