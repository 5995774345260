/***************************** Obsługa rejestrów danych ******************************/


/* Pobranie danych rejestru z serwera */
var iLoadRegistry = function(form) {
    var $datatable = form.find(".fpDataTable");

 	var registryRequest = $.extend({},iAjaxData,{
 		_this : $datatable,
 		data : iDefaultFormDataGenerator({tag:form}),
 		url : form.attr("action"),
 		success: iLoadRegistrySuccess
 	});
 	$.ajax(registryRequest);
    getSummary(form);
}

var getSummary = function(form) {
    var $datatable = form.find(".fpDataTable");
    var summaryUrl = $datatable.data("summaryurl");
    if(summaryUrl) {
        var summaryRequest = $.extend({}, iAjaxData,{
            _this : $datatable,
            data : iDefaultFormDataGenerator({tag:form}),
            url : summaryUrl,
            success: loadSummary
        });
        $.ajax(summaryRequest);
    }
}

/* Załadowanie danych do tabeli xHTML */
var iLoadRegistrySuccess = function(data, textStatus, jqXHR){
    manageExceptions.call(this, jqXHR, data);

	if($(this._this).data("iAppendRegistry"))
	    $(this._this).find("tbody").append(data);
    else
	    $(this._this).find("tbody").html(data);

    //aktualizacja
	var $form = iFindParentTag(this._this, "form");
	var $firstRow = $(this._this).find("tbody>tr:first");
	var $newCounter = ($firstRow.size() > 0) ? $firstRow.data("counter") : 0;
	var fetch = parseInt($form.find("[name$='.fetch']").val());
	var from = parseInt($form.find("[name$='.from']").val())
	$form.find("[name$='Counter']").val($newCounter);
	$form.find(".fpRegistryCounter").text((($newCounter) ? $newCounter : 0));
	$(".fpDataTable").trigger("change"); //zdarzenie zmiany w tabeli
	$(".fpDataTable .fpEditIcon").unbind("click").click(fpOpenLink);
    $(this._this).data("iAppendRegistry", false);

	if($newCounter <= (fetch + from))
        $form.find(".fpNavigate").addClass("fpDisplayNone");
    else
        $form.find(".fpNavigate").removeClass("fpDisplayNone");

    $(this._this).trigger("registry-reloaded");

    iHideMask(this._this);

    selectLastEditedRow($(this._this));
    scrollToPosition($form);
    $("*[title]").tooltip();
}

/* Załadowanie nagłówka */
var loadSummary = function(data, textStatus, jqXHR){
    manageExceptions.call(this, jqXHR, data);
    $(this._this).find("tfoot").html(data);
}

/* Wyszukiwanie w rejestrze danych */
var iSearch = function(form) {
    iShowMask(form.find(".fpDataTable"));
	form.find("[name$='.from']").val(0);
	iLoadRegistry(form);
}

/* Wywołanie wyszukiwania */
var fpSearchRegistry = function(e) {
	var $form = iFindParentTag(this, "form");
	if(e.keyCode==13 || $(this).is(".fpSearchCombo") || $(this).is(".fpSearchChange")) {
        iSearch($form);
	}
}

/* Sortowanie rejestru danych */
var fpSortRegistry = function() {
	var $form = iFindParentTag(this, "form");
	var sortDirection = $(this).data("sort").split(' ');

    iShowMask($form.find(".fpDataTable"));
	$form.find("[class$='Selected']").each(function() {
		$(this).removeClass("fpSortAscSelected").removeClass("fpSortDescSelected");
	});
	if(sortDirection[1] == "asc")
		$(this).find("span").addClass("fpSortAscSelected");
	else
		$(this).find("span").addClass("fpSortDescSelected");

	$form.find("[name$='.orderBy']").val($(this).data("sort"));
	$form.find("[name$='.from']").val(0);

	iLoadRegistry($form);
}

/* Rozszerzanie puli wyników w rejestrze */
var fpRegistryMoreResults = function() {
	var $form = iFindParentTag(this, "form");
	var fetch = parseInt($form.find("[name$='.fetch']").val());
	if(fetch !== 20) {
        $form.find("[name$='.fetch']").val(20);
    }
	var newFrom = parseInt($form.find("[name$='.from']").val()) + fetch;

    iShowMask($form.find(".fpDataTable"));
    $form.find("[name$='.from']").val(newFrom);
    $form.find(".fpDataTable").data("iAppendRegistry", true);

    savePosition();
    iLoadRegistry($form);
}

var savePosition = function() {
    sessionStorage.scrollBottom = $("form").height();
}

var saveLastEditedRow = function(srcElement) {
    if($(srcElement).closest("table").is(".fpDataTable")) {
        sessionStorage.setItem("last-entered-row", $(srcElement).closest("tr").data("id"));
    }
}

var selectLastEditedRow = function($dataTable) {
    var $dataTable = $(".factory-view").find(".fpDataTable");
    var lastEnteredRow = sessionStorage.getItem("last-entered-row");
   $dataTable
       .find("tr[data-id='" + lastEnteredRow + "']")
       .addClass("last-entered-row");

   sessionStorage.removeItem("last-entered-row");
}

var getFormCriteria = function($this) {
    var $form = $($this).closest(".factory-view").find("form");
    var formData = iDefaultFormDataGenerator({tag: $form});

    var criteria = {save: !$($this).hasClass("fpBackButton")};
    Object.keys(formData).forEach(function(data) {
        if(data.startsWith("criteria.")) {
            criteria[data] = formData[data];
        }
    });
    return criteria;
};

var setCriteria = function(criteria) {
    if(criteria == null) return;
    if(criteria.save) {
        saveCriteria(criteria);
    }
    else {
        loadCriteria();
    }
};

var saveCriteria = function(criteria) {
    Object.keys(criteria).forEach(function (key) {
        sessionStorage.setItem(key, criteria[key]);
    });
}

var loadCriteria = function() {
    $('input[type=checkbox]').attr('checked', false);
    Object.keys(sessionStorage).forEach(function (key) {
        loadCriteriaField(key);
    });

    var fetch = $("[name$='.fetch']");
    var from = $("[name$='.from']");
    fetch.val(parseInt(fetch.val())+parseInt(from.val()));
    from.val(0);
}

var loadCriteriaField = function(key) {
    var tag = $('[name="' + key + '"]');
    if (tag.length > 0) {
        var value = sessionStorage.getItem(key);
        tag.val(value);

        if(tag.attr('type') === 'checkbox') {
            tag.attr('checked', true);
        }

        if(value != '' && value != 'false') {
            tag.parent("label").addClass("active");
        }

        sessionStorage.removeItem(key);
    }
}

var reloadTable = function($form) {
    var from = $form.find("[name$='.from']");
    var fetch = $form.find("[name$='.fetch']");
    fetch.val(parseInt(from.val()) + parseInt(fetch.val()));
    from.val(0);
    iShowMask($form.find(".fpDataTable"));
    iLoadRegistry($form);
}