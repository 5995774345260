(function($) {

    $.fn.orderDetailOperations = function() {

        var $orderDetailOperationsEditForm = $(".order-operations-form");
        var $orderDetailOperationsContainer = $orderDetailOperationsEditForm.find(".order-detail-operations-container");
        var $orderDetailOperationsMaterialsForm = $(".order-detail-operation-materials-container");
        var PRODUCTION_PROCESS_WORKFLOW_SPREADSHEET = ".production-process-workflow";
        var MATERIALS_EDITING_CLASS = "construction-detail-editing-materials";
        var col1small="col-sm-8";
        var col1big="col-sm-12";
        var col2big="col-sm-4";

        assignOperationId = function() {
            var operationId = $(this).find("option:selected").data("operationid");
            $(this).next().val(operationId);
            var unitLabel = $(this).find("option:selected").data("unit-label");
            $(this).closest(".orderDetailOperation").find("input.machineUnitLabel").val(unitLabel);
        },

        bindOperationParametersChange = function($row){
            var productionProcessOperationId = $row.find("input[name='productionProcessOperationId']").val();
            var $destinationItem = $(PRODUCTION_PROCESS_WORKFLOW_SPREADSHEET).find("input[value='" + productionProcessOperationId + "']").parent();

                $row.find("input[name='effort']").change(function() {
                    $destinationItem.find("input[name='effort']").val($(this).val());
                });
                $row.find("input[name='machinePreparationTime']").change(function() {
                    $destinationItem.find("input[name='machinePreparationTime']").val($(this).val());
                });
                $row.find("textarea[name='notes']").change(function() {
                    $destinationItem.find("input[name='notes']").val($(this).val());
                });
        },

        disableOperationIntensityTab = function(e) {
            $(".fpFormTabs").tabs({disabled: [1]});
            $(".production-process-not-saved").show();
        },

        selectMaterialsEditButton = function($button) {
            unselectMaterialsEditButtons();
            $button.addClass(MATERIALS_EDITING_CLASS);
            $button.find("i")
                .removeClass("fa-edit")
                .addClass("fa-folder-open");
        },

        unselectMaterialsEditButtons = function() {
            $("." + MATERIALS_EDITING_CLASS).each(function() {
                $(this).removeClass(MATERIALS_EDITING_CLASS);
                $(this).find("i")
                    .removeClass("fa-folder-open")
                    .addClass("fa-edit");
            });
        },

        getMaterialsAutocomplete = function(){
           return $.extend({},Autocomplete.common, {
                source: function (request, response) {
                    $.ajax({
                        url: $(this.element).attr("data-url"),
                        dataType: "json",
                        data: {
                            term: request.term
                        },
                        success: function (data) {
                            response($.map(data, function (item) {
                                return {
                                    label: item.name,
                                    value: item.id,
                                    unit: item.unit,
                                    minimalAmount: item.minimalAmount,
                                    actualAmount: item.actualAmount
                                };
                            }));
                        }
                    });
                }
            })
        },

        markMaterialsUnderMin = function(list, item){
            var listItem = $("<li></li>")
            .data("item.autocomplete", item)
            .append("<a>" +  item.label + " ( " + item.actualAmount + " " + item.unit + " )</a>")
            .appendTo(list);

            if (parseFloat(item.actualAmount) < parseFloat(item.minimalAmount)) {
                listItem.addClass("raw-material-under-min");
            }

            return listItem;
        },

        bindAutocomplete = function(context) {
            var $materialsAutocomplete = $(context).find("[name='materialName']").autocomplete(getMaterialsAutocomplete());
            $(context).find("input[type='number']").change(validateMinAmount);

            if($materialsAutocomplete.size() > 0) {
                $materialsAutocomplete.each(function() {
                    $(this).data("ui-autocomplete")._renderItem = markMaterialsUnderMin;
                });
            }
        },

        bindEditMaterialsRow = function(event){
            $(".iSpreadsheetEditResourcesRow").unbind('click').on('click', openOrderDetailOperationMaterials);
        },

        openOrderDetailOperationMaterials = function(contextElem){
            selectMaterialsEditButton($(contextElem.currentTarget));
            iShowMask($orderDetailOperationsEditForm);
            loadOrderDetailOperationMaterials($(contextElem.currentTarget));
        },

        loadOrderDetailOperationMaterials = function(trigger) {
            var ajaxData = $.extend({}, iAjaxData, {
                url: trigger.data("url"),
                success: loadOrderDetailOperationMaterialsSuccess
            });
            $.ajax(ajaxData);
        },

        loadOrderDetailOperationMaterialsSuccess = function(data, textStatus, jqXHR) {
            iHideMask($orderDetailOperationsEditForm);
            if(manageExceptions.call(this, jqXHR, data)) {
                return false;
            }

            $orderDetailOperationsContainer.addClass(col1small).removeClass(col1big);
            $orderDetailOperationsMaterialsForm.addClass(col2big).html(data);
            appendMaterialsEvents($orderDetailOperationsMaterialsForm);
        },

        closeOrderDetailOperationMaterials = function(){
            unselectMaterialsEditButtons();
            $orderDetailOperationsContainer.addClass(col1big).removeClass(col1small);
            $orderDetailOperationsMaterialsForm.removeClass(col2big).html('');
        },

        saveOrderDetailOperationMaterials = function() {
            var $form = fpGetForm.call(this);
            iShowMask($form);
            unselectMaterialsEditButtons();
            var ajaxData=$.extend({},iAjaxData,{
                _this : $form,
                data : iDefaultFormDataGenerator({tag:$form}),
                url : $form.attr("action"),
                success: saveOrderDetailOperationMaterialsSuccess
            });
            $.ajax(ajaxData);
        },

        saveOrderDetailOperationMaterialsSuccess = function(data, textStatus, jqXHR) {
            if(manageExceptions.call(this, jqXHR, data)) {
                return false;
            }

            if(jqXHR.getResponseHeader("requestOK") === "true") {
                alertify.success(i18n('ChangeSaved'));
            } else {
                $orderDetailOperationsContainer.html(data);
                appendMaterialsEvents();
            }
            closeOrderDetailOperationMaterials();
        },

        appendMaterialsEvents = function(contextElement) {
            var $materialsSpreadsheet = $(contextElement).find(".iSpreadsheet");
            $materialsSpreadsheet.spreadsheet(bindAutocomplete);

            $(contextElement).find(".fpCancelButton").click(closeOrderDetailOperationMaterials);
            $(contextElement).find(".fpSaveButton").click(saveOrderDetailOperationMaterials);
            bindAutocomplete($materialsSpreadsheet);
        },

        appendEvents = function(contextElement) {
            var $spreadsheet = $(contextElement).find(".iSpreadsheet");
            $spreadsheet.spreadsheet();

            $spreadsheet.find(".form-group").each(function() {
                bindOperationParametersChange($(this));
            });

            $(contextElement).find(".fpBackButton").click(fpOpenLink);
            $(contextElement).find(".fpSaveButton").click(fpSaveForm);
            $(contextElement).find(".fpFormTabs").tabs({activate: adjustTextareas});
            $(contextElement).find(".operations-toolbox").operationsToolbox();
            $(PRODUCTION_PROCESS_WORKFLOW_SPREADSHEET).on("dirty-data", disableOperationIntensityTab);

            bindEditMaterialsRow();
        },

        init = function(contextElement) {
            appendEvents(contextElement);
        };

        return this.each(function() {
            init(this);
        });
    }

}(jQuery));