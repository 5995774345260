/**
 * Created by visix on 25.05.2017.
 */

(function($) {

    $.fn.showPlannedRealization = function() {

        var REALIZATION_DATES_CONTAINER;

        loadRealizationDates = function(isOrderRegistry) {
            var ajaxData = $.extend({},iAjaxData,{
                url : REALIZATION_DATES_CONTAINER.data("realizationdates"),
                isOrderRegistry: isOrderRegistry,
                type: 'GET',
                success: loadDatesCallback
            });
            $.ajax(ajaxData);
        };

        addTooltip = function(elem, realizationDate) {
            $(elem).addClass("iRed fpBold");
            $(elem).attr("title", (i18n('realEndOfTime') + ' ' + realizationDate));
            $(elem).tooltip();
        };

        loadPredictedEnd = function(elem, predictedEnd, plannedEnd) {
            if(predictedEnd != "") {
                if(plannedEnd == "") {
                    $(elem).text(predictedEnd);
                } else if(new Date(predictedEnd) > new Date(plannedEnd)) {
                    addTooltip(elem, predictedEnd);
                }
            }
        };

        loadWorkIntensityTimeLeft = function(elem, workIntensityTimeLeft) {
            if(workIntensityTimeLeft != "") {
                $(elem).closest("tr").find(".work-intensity-time-left").text(workIntensityTimeLeft);
            }
        };

        loadProductDatesCallback = function(elem, realizationDate) {
            var plannedEnd = $(elem).data("plannedend");
            loadPredictedEnd(elem, realizationDate, plannedEnd);
        };

        loadOrderDatesCallback = function(elem, realizationEntry) {
            var predictedEnd = realizationEntry ? realizationEntry["predictedEnd"] : "";
            var workIntensityTimeLeft = realizationEntry ? realizationEntry["workIntensityTimeLeft"] : "";
            var plannedEnd = $(elem).data("plannedend");
            loadPredictedEnd(elem, predictedEnd, plannedEnd);
            loadWorkIntensityTimeLeft(elem, workIntensityTimeLeft);
        };

        loadDatesCallback = function(data, textStatus, jqXHR) {
            var isOrderRegistry = this.isOrderRegistry;
            if(manageExceptions.call(this, jqXHR, data)) {
                return false;
            }

            REALIZATION_DATES_CONTAINER.find(".simulated-realization-date").each(function() {
                var realizationEntry =  data[$(this).data("id")];

                if(isOrderRegistry) {
                    loadOrderDatesCallback(this, realizationEntry);
                } else {
                    loadProductDatesCallback(this, realizationEntry);
                }
            });
        };

        return this.each(function() {
            REALIZATION_DATES_CONTAINER = $(this);
            var isOrderRegistry = REALIZATION_DATES_CONTAINER.is(".production-orders-registry");
            loadRealizationDates(isOrderRegistry);
        });

    }

}(jQuery));