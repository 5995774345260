var Autocomplete = {
    common: {
        source: function( request, response ) {
            $.ajax({
                url: $(this.element).attr("data-url"),
                dataType: "json",
                data: {
                    term: request.term
                },
                success: function( data ) {
                response( $.map( data, function( item ) {
                    return {
                        label: item.name,
                        value: item.id,
                        isDefault: item.default
                        };
                    }));
                }
            });
        },
        search: function() {
            if ( this.value.length < 1 ) {
                return false;
            }
        },
        focus: function() {
            // prevent value inserted on focus
            return false;
        },
        getTarget : function(elem) {
            var targetName = $(elem).attr("data-target-input");
            var targetNameContainer = $(elem).attr("data-target-input-container");

            if(targetNameContainer) {
                return $(elem).closest(targetNameContainer).find("[name='" + targetName + "']");
            }
            return $(elem).closest(".form-group").find("[name='" + targetName + "']");
        },
        select: function( event, ui ) {
            this.value = ui.item.label;
            var $target = Autocomplete.common.getTarget(this);
            if($target) {
                $(this).trigger("change");
                $target.val(ui.item.value).trigger("change");
            } else {
                console.log("Could not find Autocomplete.common target element");
            }
           return false;
        },
        open: function() {
            $("ul.ui-menu").css("max-width", "400px");
        },
        change: function() {
            var $target = Autocomplete.common.getTarget(this);
            $(this).trigger("change");

            if($target && this.value == "")
                $target.val("");
        }
    }
};