/**
 * Created by visix on 21.06.2017.
 */

(function($) {

    $.fn.autoFill = function() {

        requestFormData = function() {
            var ajaxData = $.extend({},iAjaxDataJSON,{
                url : $(this).data("autofill-url"),
                data: {
                    fillBy: $(this).val()
                },
                container: $(this).closest(".autoFill"),
                prefix: $(this).data("autofill-prefix"),
                success: fillForm
            });
            $.ajax(ajaxData);
        },

        fillForm = function(data) {
            var prefix = $(this.prefix).selector;
            var form = $(this.container);

            if(data != null) {
                Object.keys(data).forEach(function (key) {
                    form.find("input[name='" + prefix + "." + key + "'], select[name='" + prefix + "." + key + "']").val(data[key]);
                });
            } else {
                form.find("input.autoFillClean").val("");
                form.find(".new-client").show();
            }
        },

        init = function(contextElement) {
            $(contextElement).find("input[data-autofill-url]").unbind("change").on("change", requestFormData);
        };

        return this.each(function() {
            init(this);
        });
    }

}(jQuery));


