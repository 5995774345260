/**
 * @author visix
 */

(function($) {

    $.fn.productionReport = function() {

        filterPlanned = function(e) {
            if($(this).is(".active")) {
                $("[name='criteria.showPlanned']").val(false);
            } else {
                $("[name='criteria.showPlanned']").val(true);
                $("[name='criteria.status']").val(null);
            }

            fpSearchRegistry.call(this, e);
        },

        filterInProgress = function(e) {
            if($(this).is(".active")) {
                $("[name='criteria.showInProgress']").val(false);
            } else {
                $("[name='criteria.showInProgress']").val(true);
                $("[name='criteria.status']").val(null);
            }

            fpSearchRegistry.call(this, e);
        },

        filterFinished = function(e) {
            if($(this).is(".active")) {
                $("[name='criteria.showFinished']").val(false);
            } else {
                $("[name='criteria.showFinished']").val(true);
                $("[name='criteria.status']").val(null);
            }

            fpSearchRegistry.call(this, e);
        }

        var changeReportDetailsLevel = function(event) {
            $(".iMain > .fpContent").html(LOADING);

            var criteria = getFormCriteria(this);
            criteria["criteria.groupBy"] = ""; //grouping does not work for detailed report

            var ajaxData = $.extend({},iAjaxData,{
                url : $(this).data("url"),
                data: criteria,
                success: function(data, textStatus, jqXHR) {
                    fpOpenViewSuccess.call(this, data, textStatus, jqXHR);
                }
            });
            $.ajax(ajaxData);
        }

        return this.each(function() {
            $(this).find(".fpSearch").keyup(fpSearchRegistry);
            $(this).find(".fpSearchCombo").change(fpSearchRegistry);
            $(this).find(".filtering-planned").change(filterPlanned);
            $(this).find(".filtering-inprogress").change(filterInProgress);
            $(this).find(".filtering-finished").change(filterFinished);
            $(this).find(".fpSort").click(fpSortRegistry);
            $(this).find(".fpNavigate").click(fpRegistryMoreResults);
            $(this).find(".fpEditIcon, .fpAddButton, .fpBackButton").click(fpOpenLink);
            $(this).find(".fpChangeReport").click(changeReportDetailsLevel);
            $(this).find(".fpChooseDatePopover").datepicker({
                dateFormat: "yy-mm-dd"
            }).change(fpSearchRegistry);
            new ExcelExport().bind();
            getSummary($("#reportSummary").closest("form"));
        });
    }

}(jQuery));