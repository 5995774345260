/**
 * @author visix
 */

(function($) {

    $.fn.stockOperations = function() {

        var bindExcelExport = function() {
            new ExcelExport().bind();
        },

        bindQrCodeWindow = function(table) {
            $(table).find(".fpQrcode").click(showQrcode);
        },

        showQrcode = function() {
            iNewWindow($(this).data("url"),"qrcode", 600, 400);
        };

        return this.each(function() {
            $(".fpSearch").keyup(fpSearchRegistry);
            $(".fpSearchCombo").change(fpSearchRegistry);
            $(".fpSort").click(fpSortRegistry);
            $(".fpNavigate").click(fpRegistryMoreResults);
            $(".fpBackButton, .fpNewStockRelease, .fpNewStockReceipt, .fpStockTransfer, .fpStocksRemanent").click(fpOpenLink);
            $(".fpChooseDatePopover").datepicker({
                dateFormat: "yy-mm-dd"
            }).change(fpSearchRegistry);
            $(this).find(".stock-operations-registry").on("registry-reloaded", function() {
                bindExcelExport();
                bindQrCodeWindow(this);
            });

            bindExcelExport();
            bindQrCodeWindow(this);
        });

    }

}(jQuery));