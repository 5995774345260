(function($) {

    $.fn.orderToExternalSystem = function() {

        sendSummaryToExternalSystem = function() {
            if(confirm(i18n('confirmAction'))) {
                var $this = $(this);
                var form = iFindParentTag(this, "form");
                var ajaxData = $.extend({},iAjaxData,{
                    url : $(this).data("url"),
                    data : iDefaultFormDataGenerator({tag:form}),
                    type: 'GET',
                    success: function(data, textStatus, jqXHR) {
                        if(manageExceptions.call(this, jqXHR, data)) {
                            return false;
                        }
                        sendSummaryToExternalSystemSuccess();
                    }
                });
                $.ajax(ajaxData);
            } else {
                return false;
            }
        };

        sendSummaryToExternalSystemSuccess = function(status) {
            alertify.success(i18n("order.summaryToExternalSystemSent"));
            $(".fpBackButton").trigger("click");
        };

        return this.each(function() {
            $(".fpBackButton").click(fpOpenLink);
            $(".fpSendSummaryToExternalSystemButton").click(sendSummaryToExternalSystem);
        });
    }

}(jQuery));